import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpcomingSessionComponent} from './upcoming-session.component';
import {SharedModule} from '@shared/shared.module';
import {UpdateZoomDataModule} from '@shared/modules/update-zoom-data/update-zoom-data.module';


@NgModule({
  declarations: [
    UpcomingSessionComponent
  ],
  exports: [
    UpcomingSessionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UpdateZoomDataModule
  ]
})
export class UpcomingSessionModule {
}
