<div *ngIf="sessionStatus !== 'ended'"
     [ngClass]="{
      'loading__or__no__sessions': loading || (!loading && !upcomingSession),
      'upcoming__session':!loading && upcomingSession,
      'active__session':!loading && upcomingSession && sessionStatus === 'started'
      }"
     class="upcoming__session-wrapper"
     dir="rtl">
  <p *ngIf="loading || (!loading && !upcomingSession)" class="session__content">
    {{barContent}}
  </p>
  <div *ngIf="!loading && upcomingSession">
    <ng-container *ngIf="sessionStatus === 'upcoming'">
      <p class="session__content">
      <span>
         معاد الحصة القادمة في
      </span>
        <span>
        {{upcomingSession?.start | customDatePipe:'date'}}
      </span>
        <span>
        {{upcomingSession?.start | customDatePipe:'time'}}
      </span>
        <span> (بتوقيت المدرس)</span>
      </p>
    </ng-container>
    <ng-container *ngIf="sessionStatus === 'started'">
      <div (click)="enteringSession()" class="bar__actions session__content">
        <p>
          لقد بدأت الحصة
        </p>
        <p class="action">
          اضغط هنا للانضمام
        </p>
      </div>
    </ng-container>
  </div>
</div>
