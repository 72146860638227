import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {Notification} from '@core/@data/Notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  Url = `${env.ApiUrl}/teacher/notifications`;

  constructor(
    private http: HttpClient,
  ) {
  }

  MarkAsRead(ids: string[]): Observable<Notification> {
    return this.http.put<Notification>(`${this.Url}/mark-as-read,`, {notifications: ids});
  }

  GetOne(id: string): Observable<Notification> {
    return this.http.get<Notification>(`${this.Url}/${id}`);
  }

  GetAll(): Observable<ApiResponse<Notification[]>> {
    return this.http.get<ApiResponse<Notification[]>>(`${this.Url}`);
  }

  GetAllWithPagination(page: number = 1): Observable<ApiResponse<Notification[]>> {
    return this.http.get<ApiResponse<Notification[]>>(`${this.Url}?page=${page}`);
  }

  UnReadCount(): Observable<{ count: number; }> {
    return this.http.get<{ count: number; }>(`${this.Url}/unread-count`);
  }
}// End of Class
