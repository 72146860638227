import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CreateSessionDialogueComponent} from './create-session-dialogue.component';

export interface CreateSessionDialogueClose {
  selectedId: number;
  invitationsNumber: number;
  isConfirmed: boolean;
}

{
}

@Injectable({
  providedIn: 'root'
})
export class CreateSessionDialogueService {
  dialogRef!: MatDialogRef<CreateSessionDialogueComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(options: {
    title: any;
    cancelText: any;
    confirmText?: any;
    message?: any;
  }): void {
    this.dialogRef = this.dialog.open(CreateSessionDialogueComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
    });
  }

  public confirmed(): Observable<CreateSessionDialogueClose> {
    return this.dialogRef.afterClosed().pipe(
      take(1)
    );
  }
}

