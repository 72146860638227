import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GeneralService} from '@core/@http/services/general.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorHandlingService} from '@shared/services/error-handling.service';
import {ConfirmDialogService} from '@shared/components/common-components/confirm-dialouge/confirm.service';
import {isPlatformBrowser} from '@angular/common';
import {TeacherSession, UpcomingSession} from '@core/@data/CoursesData/Session';
import {ToasterService} from '@shared/services/toastr.service';
import {SessionService} from '@core/@http/services/session.service';
import {convertTimeToNumber} from '@shared/functions/time-functions';

@Component({
  selector: 'app-upcoming-session-update-zoom-data',
  templateUrl: './upcoming-session-update-zoom-data.component.html',
  styleUrls: ['./upcoming-session-update-zoom-data.component.scss']
})
export class UpcomingSessionUpdateZoomDataComponent implements OnInit {
  loading = true;
  formLoading = false;
  updateProfileForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private generalService: GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: {
      session: TeacherSession | UpcomingSession
    },
    private mdDialogRef: MatDialogRef<UpcomingSessionUpdateZoomDataComponent>,
    private errorHandler: ErrorHandlingService,
    private toasterService: ToasterService,
    private confirmService: ConfirmDialogService,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private sessionService: SessionService,
  ) {
  }

  ngOnInit(): void {
    console.log(this.data.session);
    console.log(this.data?.session?.minutes_to_start);
    this.updateProfileForm = this.fb.group({
      link: ['', [Validators.required]],
    });
    this.updateProfileForm.controls.link.valueChanges.subscribe((res) => {
      this.updateProfileForm.get('link')?.setValue(res?.trim(), {emitEvent: false});
      this.updateProfileForm.markAllAsTouched();
    });
  }

  cancel(): void {
    this.close({statue: false, link: ''});
  }

  public close(data: { statue: boolean, link: string }): void {
    this.mdDialogRef.close({statue: data.statue, link: data.link});
  }

  onSubmit(): void {
    console.log('id', this.data?.session?.id);
    this.formLoading = true;
    this.generalService.UpdateZoomData(this.updateProfileForm.controls.link.value, this.data?.session?.id).subscribe(
      (res) => {
        console.log('res ', res);
        if (
          (this.data?.session?.minutes_to_start >= 0 &&
            this.data?.session?.minutes_to_start <= convertTimeToNumber(this.data?.session.duration))
          || (this.data?.session.force_start === 1)
        ) {
          this.formLoading = false;
          this.close({statue: true, link: this.updateProfileForm.controls.link.value});
          this.toasterService.showLoading('جارى الدخول للحصة');
          this.sessionService.JoinSession(this.data?.session?.id).subscribe(
            (results) => {
              this.toasterService.hideLoading();
              if (isPlatformBrowser(this.platformId)) {
                window.open(results?.url as string, '_blank');
              }
              this.close({statue: true, link: this.updateProfileForm.controls.link.value});
              this.confirmService.open({
                title: 'فى حالة واجهتك اى مشكلة فى دخول الحصة',
                isSessionLink: true,
                message: results?.url as string,
                cancelText: 'إغلاق'
              });
              this.confirmService.confirmed().subscribe();
            },
            (error) => {
              this.toasterService.hideLoading();
              this.errorHandler.HandelAuthErrors(error.error.errors, error.status, error.message);
            });
        } else {
          this.toasterService.showSuccess(res?.message);
          this.close({statue: true, link: this.updateProfileForm.controls.link.value});
          this.formLoading = false;
        }
      }, (error) => {
        console.log(error);
        this.errorHandler.HandelAuthErrors(error.error.errors, error.status, error.message);
        this.formLoading = false;
        this.close({statue: false, link: ''});
      });
  }
}


// if (this.data?.session?.minutes_to_start >= 0 &&
//   this.data?.session?.minutes_to_start <= convertTimeToNumber(this.data?.session.duration) &&
//   this.data?.session?.force_start === 1) {
//   this.formLoading = false;
//   if (isPlatformBrowser(this.platformId)) {
//     window.open(this.updateProfileForm.controls.link.value, '_blank');
//   }
//   this.close({statue: true, link: this.updateProfileForm.controls.link.value});
//   this.confirmService.open({
//     title: 'فى حالة واجهتك اى مشكلة فى دخول الحصة',
//     isSessionLink: true,
//     message: this.updateProfileForm.controls.link.value,
//     cancelText: 'إغلاق'
//   });
//   this.confirmService.confirmed().subscribe(() => {
//   });
// } else {
//   this.toasterService.showSuccess(res?.message);
//   this.close({statue: true, link: this.updateProfileForm.controls.link.value});
//   this.formLoading = false;
// }
