import {Pipe, PipeTransform} from '@angular/core';
import {AppointmentInvitationStatus} from '@core/@data/CoursesData/Session';

@Pipe({
  name: 'getInvitationsStates'
})
export class GetInvitationsStatesPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      switch (value) {
        case AppointmentInvitationStatus.Pending: {
          return 'قيد الانتظار';
        }
        case AppointmentInvitationStatus.Accepted: {
          return 'دعوة مقبولة';
        }
        case AppointmentInvitationStatus.Rejected: {
          return 'دعوة مرفوضة';
        }
        default: {
          return '';
        }
      }
    } else {
      return '';
    }
  }
}
