import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-small-nav-link',
  templateUrl: './small-nav-link.component.html',
  styleUrls: ['./small-nav-link.component.scss']
})
export class SmallNavLinkComponent implements OnInit {
  @Input() link!: string;
  @Input() color!: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
