import {Pipe, PipeTransform} from '@angular/core';
import {JoinSessionState, SessionState} from '@core/@data/CoursesData/Session';


export function GetJoinState(state: number): string {
  switch (state) {
    case JoinSessionState.NOT_JOINED_BY_ALL: {
      return 'لم يحضر أحد';
    }
    case JoinSessionState.JOINED_BY_STUDENT: {
      return 'تم حضور الطالب فقط';
    }
    case JoinSessionState.JOINED_BY_TEACHER: {
      return 'تم حضور المدرس فقط';
    }
    case JoinSessionState.JOINED_BY_ALL: {
      return 'تم حضور كلاهما';
    }
    default: {
      return '';
    }
  }
}

export function GetSessionState(state: number): string {
  switch (state) {
    case SessionState.AVAILABLE: {
      return 'حصة متاحة';
    }
    case SessionState.BOOKED: {
      return 'حصة محجوزة';
    }
    case SessionState.CONFIRMED: {
      return 'حصة مكتملة';
    }
    case  SessionState.CANCELED: {
      return 'حصة ملغاه';
    }
    case  SessionState.REFUNDED: {
      return 'تم إرجاع هذة الحصة إلى رصيدك';
    }
    case  SessionState.STARTED: {
      return 'بدأت الحصة';
    }
    case  SessionState.DONE: {
      return 'انتهت الحصة';
    }
    default: {
      return '';
    }
  }
}

@Pipe({
  name: 'getSessionStates'
})
export class GetSessionStatesPipe implements PipeTransform {
  transform(value: number, isStatue: boolean): string {
    if (isStatue) {
      return GetSessionState(value);
    } else {
      return GetJoinState(value);
    }
  }
}
