import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalstorageService} from '../services/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  token!: string;

  constructor(private localStorageService: LocalstorageService,
  ) {

  }

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // modify request
    request = request.clone({
      // url: request.url.replace('http://', 'https://'),
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.state$.getValue()?.token}`,
        Accept: 'application/json',
        'Accept-Language': 'ar'
      },
    });

    // console.log('---- request ----');
    // console.log(request);
    // console.log('--- end of request ---');

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // console.log(' all looks good');
            // http response status code
            // console.log(event.status);
          }
        },
        (error) => {
          // http response status code
          // console.log('---- response ----');
          // console.error('status code:');
          // console.error(error.status);
          // console.error(error.errors);
          // console.log('--- end of response ---');
        }
      )
    );
  }
}

// return this.authService.userDataBehaviorSubject.pipe(
//   take(1),
//   exhaustMap((user) => {
//     if (!user) {
//       return next.handle(request);
//     }
//     const modifiedReq = request.clone({
//       // url: request.url.replace('http://', 'https://'),
//       setHeaders: {
//         Authorization: `Bearer ${this.localStorageService.state$.getValue()?.token}`,
//         Accept: 'application/json'
//       },
//     });
//     return next.handle(modifiedReq);
//   })
// );
// }
