import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  LoginDataObject,
  LoginUserData,
  ResetPasswordDataObject,
  UpdateProfileData
} from '@core/@data/UsersData/UserData';
import {LocalstorageService} from '@core/@http/services/localstorage.service';
import {ToasterService} from '@shared/services/toastr.service';
import {Router} from '@angular/router';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';
import {Timezone} from '@core/@data/General/time-zone';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TeacherAuthService {
  Url = `${env.ApiUrl}/teacher`;
  userDataBehaviorSubject = new BehaviorSubject<LoginUserData | null>(null);
  testUser = false;

  constructor(
    private localStorageService: LocalstorageService,
    private http: HttpClient,
    private toastService: ToasterService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.profileUser();
  }


  Login(data: LoginDataObject): Observable<ApiResponse<LoginUserData | undefined>> {
    return this.http.post<ApiResponse<LoginUserData | undefined>>(`${this.Url}/login`, data).pipe(
      // tslint:disable-next-line:no-shadowed-variable
      tap((data) => {
        this.localStorageService.setState('token', data?.token);
        this.profileUser();
      }));
  }

  Logout(): void {
    this.http.post(`${this.Url}/logout`, {}).subscribe(
      (_) => {
        this.userDataBehaviorSubject.next(null);
        this.localStorageService.setState('token', null);
        this.localStorageService.ClearStorage();
        this.router.navigate(['/user/login']).then();
      },
      () => {
        this.toastService.showFail('SomeThing Wrong!!');
        this.localStorageService.ClearStorage();
        this.router.navigate(['/user/login']).then();
        location.reload();
      }
    );
  }

  ForgetPassword(email: string): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${this.Url}/forget-password`, {email});
  }

  ActivateEmail(email: string, code: number): Observable<ApiResponse<LoginUserData | undefined>> {
    return this.http.post<ApiResponse<LoginUserData | undefined>>(`${this.Url}/activate`, {email, code});
  }

  ReSendActivationCode(email: string): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${this.Url}/resend-activation`, {email});
  }

  ResetPassword(data: ResetPasswordDataObject): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(`${this.Url}/reset-password`, data);
  }

  CheckAuthAndProfile(): Observable<ApiResponse<LoginUserData>> {
    return this.http.get<ApiResponse<LoginUserData>>(`${this.Url}/show-profile`);
  }

  profileUser(): void {
    const localItem = this.localStorageService.state$.getValue()?.token;
    if (localItem) {
      this.CheckAuthAndProfile().subscribe(
        (res) => {
          this.userDataBehaviorSubject.next(res.data as LoginUserData);
          if (res && res.data?.email?.toLowerCase() === 'Test2@qoraan.com'.toLowerCase()) {
            this.testUser = true;
          }
        },
        (err) => {
          this.userDataBehaviorSubject.next(null);
          this.localStorageService.setState('token', null);
          this.localStorageService.ClearStorage();
          this.router.navigate(['/']).then();
        });
    }
  }

  profileUser2(): void {
    this.CheckAuthAndProfile().subscribe(
      (res) => {
        this.userDataBehaviorSubject.next(res.data as LoginUserData);
        this.router.navigate(['/']).then(() => {
            if (isPlatformBrowser(this.platformId)) {
              location.reload();
            }
          }
        );
      },
      (err) => {
        this.userDataBehaviorSubject.next(null);
        this.localStorageService.ClearStorage();
        this.router.navigate(['/']).then();
      });
  }

  UpdateProfile(data: UpdateProfileData | FormData): Observable<ApiResponse<LoginUserData>> {
    return this.http.post<ApiResponse<LoginUserData>>(`${this.Url}/update-profile`, data);
  }

  UpdateZoomCredentials(email: string, token: string): Observable<ApiResponse<null>> {
    return this.http.put<ApiResponse<null>>(`${this.Url}/update-zoom-credentials`, {
      email, zoom_jwt_token: token
    });
  }

  GetTimeZones(): Observable<ApiResponse<Timezone[]>> {
    return this.http.get<ApiResponse<Timezone[]>>(`${this.Url}/timezones`);
  }
}// End of Class
