import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OpenUrlService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  OpenUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log('url ', url);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.classList.add('d-none');
      a.href = url;
      a.setAttribute('target', '_blank');
      a.click();
      document.body.removeChild(a);
    }
  }
}

