import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeYoutubeUrl'
})
export class SafeYoutubeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string): any {
    // ^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$

    if (url !== null && /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/.test(url)) {
      const newURL = url.split('=');
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${newURL[1]}?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&autohide=0`);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/LdgBV8qAxC8?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&autohide=0`);

    }
    // console.log(newURL, newURL[1]);
// https://www.youtube.com/embed?v=WhuUNnXiGO0
// tslint:disable-next-line:max-line-length
//     <!--<iframe src="https://www.youtube-nocookie.com/embed/LdgBV8qAxC8?loop=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3&theme=light&autohide=0" width="562" height="315" frameborder="0"></iframe>-->

  }

  // https://www.youtube.com/watch?v=LdgBV8qAxC8
}
