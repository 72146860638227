import {NgModule} from '@angular/core';

import {components, directives, imports, pipes} from '@shared/index';


@NgModule({
  imports: [
    ...imports
  ],
  declarations: [
    ...pipes,
    ...directives,
    ...components,
  ],
  exports: [
    ...imports,
    ...pipes,
    ...directives,
    ...components,
  ]
})
export class SharedModule {
}
