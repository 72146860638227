<section class="navbar__main__toolbar">
  <!--  White Toolbar-->
  <mat-toolbar class="main__toolbar" dir="rtl">
    <ng-template *ngTemplateOutlet="logo"></ng-template>
    <ng-template *ngTemplateOutlet="guest__links"></ng-template>
    <span class="example-spacer"></span>
    <ng-template *ngTemplateOutlet="actions__container"></ng-template>
    <ng-template *ngTemplateOutlet="toggle__button"></ng-template>
  </mat-toolbar>
  <mat-sidenav-container class="example-container fxFill" dir="rtl">
    <!--  Side Nav  -->
    <mat-sidenav #sidenav class="flex-column">
      <ng-template *ngTemplateOutlet="sideBarContent"></ng-template>
    </mat-sidenav>
    <!-- Content  -->
    <mat-sidenav-content>
      <ng-content></ng-content>
      <router-outlet></router-outlet>
      <app-scroll-to-top></app-scroll-to-top>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>
<ng-template #guest__links>
  <div class="fxMainBar">
    <!--    <app-nav-link [isGreen]="isGreen" [link]="'/our-courses'" [name]="'الدورات والخطط '"></app-nav-link>-->
    <!--    <app-nav-link [isGreen]="isGreen" [link]="'/our-teachers'" [name]="'المعلمين '"></app-nav-link>-->
    <!--    &lt;!&ndash;    <app-nav-link [isGreen]="isGreen" [link]="'/book-course'" [name]="'خطوات الاشتراك '"></app-nav-link>&ndash;&gt;-->
    <!--    <app-nav-link [isGreen]="isGreen" [link]="'/home/our-best-students'" [name]="'أفضل طلابنا'"></app-nav-link>-->
    <!--    <app-nav-link [isGreen]="isGreen" [link]="'/home/frequently-asked-questions'" [name]="'الأسئلة الشائعة'">-->
    <!--    </app-nav-link>-->
    <!--    <app-nav-link [isGreen]="isGreen" [link]="'/home/about-us'" [name]="'تواصل معنا'"></app-nav-link>-->
  </div>
</ng-template>
<ng-template #logo>
  <div class="d-flex align-items-center justify-content-start">
    <a class="navbar-brand" routerLink="/">
      <img [src]="'./assets/images/logo/logo wide green (1).png'" alt="logo" class="main__logo__img"></a>
  </div>
</ng-template>
<ng-template #actions__container>
  <div class="actions__container fxMainBar">
    <a class="button outlined__button_white" routerLink="/user/login"> تسجيل الدخول </a>
  </div>
</ng-template>
<ng-template #toggle__button>
  <div class="fxButton">
    <button (click)="sidenav?.toggle()" aria-label="Example icon-button with menu icon" class="example-icon"
            color="primary" mat-mini-fab>
      <svg fill="#FFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
      </svg>
    </button>
  </div>
</ng-template>
<ng-template #sideBarContent>
  <div class="sidebar__content-container">
    <!--   الدورات و الخطط      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/our-courses'">-->
    <!--      الدورات و الخطط-->
    <!--    </app-small-nav-link>-->
    <!--  المعلمين      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/our-teachers'">-->
    <!--      المعلمين-->
    <!--    </app-small-nav-link>-->
    <!--  خطوات الاشتراك      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/book-course'">-->
    <!--      خطوات الاشتراك-->
    <!--    </app-small-nav-link>-->
    <!--  أفضل طلابنا      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/home/our-best-students'">-->
    <!--      أفضل طلابنا-->
    <!--    </app-small-nav-link>-->
    <!--  الأسئلة الشائعة      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/frequently-asked-questions'">-->
    <!--      الأسئلة الشائعة-->
    <!--    </app-small-nav-link>-->
    <!--  عن المنصة      -->
    <!--    <app-small-nav-link [color]="'primary'" [link]="'/home/about-us'">-->
    <!--      تواصل معنا-->
    <!--    </app-small-nav-link>-->
  </div>
  <!--      Actions         -->
  <a class="main__navbar--side--links sidebar__actions" color="primary" mat-button>تسجيل الدخول</a>
</ng-template>
