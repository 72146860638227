import {NgModule} from '@angular/core';

import {MyCalendarRoutingModule} from './my-calendar-routing.module';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {SharedModule} from '@shared/shared.module';
import {MyCalendarComponent} from './pages/my-calendar/my-calendar.component';
import {
  CreateSessionDialogueComponent
} from '@app/modules/my-calendar/components/create-session-dialouge/create-session-dialogue.component';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule} from '@angular/material/autocomplete';
import {CloseScrollStrategy, Overlay} from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);


@NgModule({
  declarations: [
    MyCalendarComponent,
    CreateSessionDialogueComponent
  ],
  imports: [
    MyCalendarRoutingModule,
    SharedModule,
    FullCalendarModule,
    MatAutocompleteModule
  ],
  providers: [
    {provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]}
  ]
})
export class MyCalendarModule {
}
