import {Component, OnInit} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TeacherAuthService} from '@core/@http/services/teacher-auth.service';

@Component({
  selector: 'app-main-small-bar',
  templateUrl: './small-bar.component.html',
  styleUrls: ['./small-bar.component.scss']
})
export class SmallBarComponent implements OnInit {
  isMatches = false;

  constructor(public teacherAuthService: TeacherAuthService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }


  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 959px)']).subscribe((result) => {
      this.isMatches = result.matches;
    });
  }

  Logout(): void {
    this.teacherAuthService.Logout();
  }
}
