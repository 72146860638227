import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {LocalstorageService} from '../services/localstorage.service';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {
  constructor(
    private localstorageService: LocalstorageService,
    public router: Router) {
  }

  canActivate(): boolean {
    if (
      !this.localstorageService.state$?.value?.token ||
      this.localstorageService.state$?.value?.token === ''
    ) {
      this.router.navigate(['/user/login']).then();
      // console.log('in auth guard false');
      return false;
    }
    // console.log('in auth guard true');
    return true;
  }

}
