import {Component} from '@angular/core';

@Component({
  selector: 'app-main-nav-bar',
  template: `
    <app-main-auth-navbar *showAuthed></app-main-auth-navbar>
    <app-main-guest-navbar *showGuest></app-main-guest-navbar>
  `,
})
export class NavBarComponent {
  constructor() {
  }
}
