import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnInit {
  @Input() link!: string;
  @Input() name!: string;
  @Input() isGreen!: boolean;
  @Input() isExact = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
