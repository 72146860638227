import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-guest-navbar',
  templateUrl: './guest-navbar.component.html',
  styleUrls: ['./guest-navbar.component.scss']
})
export class GuestNavbarComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
