import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ConfirmedValidator} from '@shared/validatores/confirmed.validator';

export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent implements ControlValueAccessor, OnInit, OnDestroy {
  hide = true;
  hide2 = true;
  form!: FormGroup;
  subscriptions: Subscription[] = [];
  @Input() style: any;
  @Input() isRequired!: boolean;
  @Input() isGrid = true;


  constructor(private formBuilder: FormBuilder) {

  }

  get value(): PasswordFormValues {
    return this.form.value;
  }

  set value(value: PasswordFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get passwordControl(): any {
    return this.form.controls.password;
  }

  get confirmPasswordControl(): any {
    return this.form.controls.confirmPassword;
  }

  ngOnInit(): any {
    this.form = this.formBuilder.group({
      password: ['', [Validators.minLength(8)]],
      confirmPassword: ['', [Validators.minLength(8)]]
    }, {
      validator: ConfirmedValidator('password', 'confirmPassword'),
    });
    this.subscriptions.push(this.form.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    }));
    if (this.isRequired) {
      // @ts-ignore
      this.form.get('password').setValidators([Validators.required, Validators.minLength(8)]);
      // @ts-ignore
      this.form.get('confirmPassword').setValidators([Validators.required, Validators.minLength(8)]);
    }
  }

  ngOnDestroy(): any {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  registerOnChange(fn: any): any {
    this.onChange = fn;
  }

  writeValue(value: PasswordFormValues | null): any {
    if (value) {
      this.value = value;
    }
    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn: any): any {
    this.onTouched = fn;
  }

  validate(_: FormControl): any {
    return this.form.valid ? null : {passwords: {valid: false}};
  }

  reset(): any {
    this.form.reset();
  }

}
