<div class="main__container">
  <div class="row m-0">
    <div [class.d-none]="isMatches" class="col-md-3">
      <div class="sidebar__main_container">
        <mat-nav-list disableRipple="true">
          <mat-list-item routerLink="/my-account/my-schedule" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">جدول حصصى</p>
            </div>
          </mat-list-item>
          <mat-list-item routerLink="/my-account/available-sessions" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">الحصص المتاحة</p>
            </div>
          </mat-list-item>
          <mat-list-item routerLink="/my-account/my-profile" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">الملف الشخصى</p>
            </div>
          </mat-list-item>
          <!--          <mat-list-item routerLink="/my-account/my-courses" routerLinkActive="sidebar__active">-->
          <!--            <div class="sidebar__item">-->
          <!--              <div class="sidebar__item-icon">-->
          <!--                <svg fill="#000000" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">-->
          <!--                  <path d="M0 0h24v24H0V0z" fill="none"/>-->
          <!--                  <path-->
          <!--                    d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12z"/>-->
          <!--                </svg>-->
          <!--              </div>-->
          <!--              <p class="sidebar__item-content">دوراتى</p>-->
          <!--            </div>-->
          <!--          </mat-list-item>-->
          <!--          <mat-list-item routerLink="/my-account/my-insights" routerLinkActive="sidebar__active">-->
          <!--            <div class="sidebar__item">-->
          <!--              <div class="sidebar__item-icon">-->
          <!--                <svg enable-background="new 0 0 24 24" fill="#000000" height="24px" viewBox="0 0 24 24" width="24px"-->
          <!--                     xmlns="http://www.w3.org/2000/svg">-->
          <!--                  <g>-->
          <!--                    <rect fill="none" height="24" width="24"/>-->
          <!--                  </g>-->
          <!--                  <g>-->
          <!--                    <g>-->
          <!--                      <path-->
          <!--                        d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z"/>-->
          <!--                      <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"/>-->
          <!--                      <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"/>-->
          <!--                    </g>-->
          <!--                  </g>-->
          <!--                </svg>-->
          <!--              </div>-->
          <!--              <p class="sidebar__item-content">احصائياتى</p>-->
          <!--            </div>-->
          <!--          </mat-list-item>-->
          <mat-list-item routerLink="/my-account/my-appointments-invitations" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0V0z" fill="none"/>
                  <path
                    d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">دعوات الحصص</p>
            </div>
          </mat-list-item>
          <mat-list-item routerLink="/my-account/all-rates" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg enable-background="new 0 0 24 24" fill="#000000" height="24px" viewBox="0 0 24 24" width="24px"
                     xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <rect fill="none" height="24" width="24"/>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z"/>
                      <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"/>
                      <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"/>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="sidebar__item-content">تقييمات الطلاب</p>
            </div>
          </mat-list-item>
          <mat-list-item routerLink="/my-account/my-students" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.825 20.3V17.3Q0.825 16.375 1.288 15.625Q1.75 14.875 2.525 14.475Q4.1 13.675 5.725 13.287Q7.35 12.9 9.025 12.9Q10.7 12.9 12.325 13.287Q13.95 13.675 15.5 14.45Q16.275 14.85 16.75 15.613Q17.225 16.375 17.225 17.3V20.3ZM16.95 13.075Q18.175 13.25 19.25 13.6Q20.325 13.95 21.275 14.45Q22.2 14.95 22.688 15.637Q23.175 16.325 23.175 17.125V20.3H19.3V17.1Q19.3 15.975 18.7 14.938Q18.1 13.9 16.95 13.075ZM9.025 11.9Q7.325 11.9 6.125 10.7Q4.925 9.5 4.925 7.8Q4.925 6.1 6.125 4.9Q7.325 3.7 9.025 3.7Q10.725 3.7 11.925 4.9Q13.125 6.1 13.125 7.8Q13.125 9.5 11.925 10.7Q10.725 11.9 9.025 11.9ZM19.3 7.8Q19.3 9.5 18.1 10.7Q16.9 11.9 15.2 11.9Q14.925 11.9 14.513 11.837Q14.1 11.775 13.8 11.675Q14.475 10.875 14.838 9.875Q15.2 8.875 15.2 7.8Q15.2 6.725 14.838 5.725Q14.475 4.725 13.8 3.925Q14.15 3.775 14.5 3.737Q14.85 3.7 15.2 3.7Q16.9 3.7 18.1 4.9Q19.3 6.1 19.3 7.8ZM3.1 18.025H14.95V17.325Q14.95 17.05 14.812 16.825Q14.675 16.6 14.45 16.5Q13.125 15.825 11.775 15.5Q10.425 15.175 9.025 15.175Q7.625 15.175 6.263 15.5Q4.9 15.825 3.6 16.5Q3.375 16.6 3.237 16.825Q3.1 17.05 3.1 17.325ZM9.025 9.725Q9.8 9.725 10.363 9.162Q10.925 8.6 10.925 7.8Q10.925 7 10.363 6.438Q9.8 5.875 9.025 5.875Q8.225 5.875 7.662 6.438Q7.1 7 7.1 7.8Q7.1 8.575 7.662 9.15Q8.225 9.725 9.025 9.725ZM9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8ZM9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">طلابي</p>
            </div>
          </mat-list-item>
          <mat-list-item routerLink="/my-account/group-events" routerLinkActive="sidebar__active">
            <div class="sidebar__item">
              <div class="sidebar__item-icon">
                <svg fill="#000000" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.825 20.3V17.3Q0.825 16.375 1.288 15.625Q1.75 14.875 2.525 14.475Q4.1 13.675 5.725 13.287Q7.35 12.9 9.025 12.9Q10.7 12.9 12.325 13.287Q13.95 13.675 15.5 14.45Q16.275 14.85 16.75 15.613Q17.225 16.375 17.225 17.3V20.3ZM16.95 13.075Q18.175 13.25 19.25 13.6Q20.325 13.95 21.275 14.45Q22.2 14.95 22.688 15.637Q23.175 16.325 23.175 17.125V20.3H19.3V17.1Q19.3 15.975 18.7 14.938Q18.1 13.9 16.95 13.075ZM9.025 11.9Q7.325 11.9 6.125 10.7Q4.925 9.5 4.925 7.8Q4.925 6.1 6.125 4.9Q7.325 3.7 9.025 3.7Q10.725 3.7 11.925 4.9Q13.125 6.1 13.125 7.8Q13.125 9.5 11.925 10.7Q10.725 11.9 9.025 11.9ZM19.3 7.8Q19.3 9.5 18.1 10.7Q16.9 11.9 15.2 11.9Q14.925 11.9 14.513 11.837Q14.1 11.775 13.8 11.675Q14.475 10.875 14.838 9.875Q15.2 8.875 15.2 7.8Q15.2 6.725 14.838 5.725Q14.475 4.725 13.8 3.925Q14.15 3.775 14.5 3.737Q14.85 3.7 15.2 3.7Q16.9 3.7 18.1 4.9Q19.3 6.1 19.3 7.8ZM3.1 18.025H14.95V17.325Q14.95 17.05 14.812 16.825Q14.675 16.6 14.45 16.5Q13.125 15.825 11.775 15.5Q10.425 15.175 9.025 15.175Q7.625 15.175 6.263 15.5Q4.9 15.825 3.6 16.5Q3.375 16.6 3.237 16.825Q3.1 17.05 3.1 17.325ZM9.025 9.725Q9.8 9.725 10.363 9.162Q10.925 8.6 10.925 7.8Q10.925 7 10.363 6.438Q9.8 5.875 9.025 5.875Q8.225 5.875 7.662 6.438Q7.1 7 7.1 7.8Q7.1 8.575 7.662 9.15Q8.225 9.725 9.025 9.725ZM9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8Q9.025 7.8 9.025 7.8ZM9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Q9.025 15.175 9.025 15.175Z"/>
                </svg>
              </div>
              <p class="sidebar__item-content">الحصص الجماعية</p>
            </div>
          </mat-list-item>
          <ng-container *ngIf="teacherAuthService.testUser">
            <mat-list-item routerLink="/certificates-generation" routerLinkActive="sidebar__active">
              <div class="sidebar__item">
                <div class="sidebar__item-icon">
                  <svg fill="#000000" height="24" viewBox="0 -960 960 960" width="24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"/>
                  </svg>
                </div>
                <p class="sidebar__item-content">إصدار الشهادات</p>
              </div>
            </mat-list-item>
          </ng-container>
          <mat-list-item (click)="Logout()" class="item__list-danger">
            <div class="sidebar__item item__danger">
              <div class="sidebar__item__icon-danger">
                <svg enable-background="new 0 0 24 24" fill="#F30000" height="24px" viewBox="0 0 24 24" width="24px"
                     xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path d="M0,0h24v24H0V0z" fill="none"/>
                  </g>
                  <g>
                    <path
                      d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"/>
                  </g>
                </svg>
              </div>
              <p class="sidebar__item-content">تسجيل الخروج</p>
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
    <div [class.col-md-9]="!isMatches" class="col-12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
