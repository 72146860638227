import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  public state$ = new BehaviorSubject<{ [key: string]: any }>({});
  private readonly stateKey = 'state';
  private state!: { [key: string]: any };

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // @Inject('LocalStorage') public localStorage: Storage
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      this.state = JSON.parse(localStorage.getItem(this.stateKey));
      Object.freeze(this.state);
      this.state$.next(this.state);
    } else {
      return;
    }
  }

  GetValue(data: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
      const localItem = localStorage.getItem(data);
      if (localItem !== null) {
        return localStorage.getItem(data);
      }
      return null;
    } else {
      return null;
    }
  }

  SetValue(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, value);
    }
  }

  GetObject(data: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
      const localItem = localStorage.getItem(data);
      if (localItem !== null) {
        return JSON.parse(localItem);
      }
      return null;
    } else {
      return null;
    }
  }

  SetObject(key: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  ClearStorage(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }

  public setState(key: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.state = {...this.state, [key]: value};
      localStorage.setItem(this.stateKey, JSON.stringify(this.state));
      Object.freeze(this.state);
      this.state$.next(this.state);
    } else {
      return;
    }
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }
    } else {
      return;
    }
  }
} // End of Class
