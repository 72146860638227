import {Pipe, PipeTransform} from '@angular/core';
import {convertTimeFrom24To122} from "@shared/functions/time-functions";
 
@Pipe({
  name: 'customDatePipe',
  pure: true
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any, data: 'date' | 'time'): string {
    const arr = value.split(' ');
    if (data === 'date') {
      return arr[0];
    } else {
      return convertTimeFrom24To122(arr[1]);
    }
  }
}
