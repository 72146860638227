import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {TeacherAuthService} from '@core/@http/services/teacher-auth.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[showAuthed]'
})
export class AuthDirective implements OnInit {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public authService: TeacherAuthService
  ) {
  }

  ngOnInit(): void {
    this.showAuthed();
  }

  showAuthed(): void {
    this.authService.userDataBehaviorSubject.subscribe((isAuthenticated) => {
      if (isAuthenticated !== null && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}

