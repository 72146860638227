import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CalendarOptions, DateSelectArg, EventApi, EventClickArg, EventInput} from '@fullcalendar/angular';
import {DatePipe} from '@angular/common';
import {CreateSession, TeacherSession} from '@core/@data/CoursesData/Session';
import {ConfirmDialogService} from '@shared/components/common-components/confirm-dialouge/confirm.service';
import {SessionService} from '@core/@http/services/session.service';
import {ToasterService} from '@shared/services/toastr.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {checkSlotDuration, getSafeDate} from '@shared/functions/time-functions';
import {forkJoin} from 'rxjs';
import {TeacherAuthService} from '@core/@http/services/teacher-auth.service';
import {
  CreateSessionDialogueClose,
  CreateSessionDialogueService
} from '@app/modules/my-calendar/components/create-session-dialouge/create-session-dialogue.service';
import {ErrorHandlingService} from '@shared/services/error-handling.service';


let eventGuid = 0;

export function createEventId(): any {
  return String(eventGuid++);
}

@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.scss']
})
export class MyCalendarComponent implements OnInit {
  loading = true;
  events: EventInput[] = [];
  calendarVisible = true;
  calendarOptions: CalendarOptions = {} as CalendarOptions;
  currentEvents: EventApi[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private confirmService: ConfirmDialogService,
    private createSessionDialogueService: CreateSessionDialogueService,
    private breakpointObserver: BreakpointObserver,
    private toasterService: ToasterService,
    private errorHandler: ErrorHandlingService,
    public teacherAuthService: TeacherAuthService,
    public datePipe: DatePipe) {
    this.calendarOptions = {
      headerToolbar: {
        left: '',
        center: 'prev,title,next',
        right: ''
      },
      initialView: 'timeGridWeek',
      initialEvents: this.events,
      handleWindowResize: true,
      weekNumberCalculation: 'ISO',
      weekNumbers: false,
      selectable: true /*Preview only*/,
      eventOverlap: false,
      weekends: true,
      editable: true,
      selectMirror: true,
      dayMaxEvents: true,
      locale: 'ar',
      slotDuration: '00:30:00',
      defaultTimedEventDuration: '00:30:00',
      selectOverlap: false,
      scrollTime: '08:00',
      direction: 'rtl',
      firstDay: 0,
      contentHeight: 'calc( 100vh - 80px)',
      height: 'calc( 100vh - 80px)',
      eventClick: this.handleEventClick.bind(this),
      select: this.handleDateSelect.bind(this),
      eventsSet: this.handleEvents.bind(this),
      timeZone: this.teacherAuthService.userDataBehaviorSubject.value?.timezone ?? 'Africa/cairo'
    };
  }

  ngOnInit(): void {
    this.breakpointObserver.observe('(max-width: 762px)').subscribe((result) => {
      this.calendarOptions.initialView = result.matches ?
        'timeGridDay' : 'timeGridWeek';
    });
    forkJoin([
      this.sessionService.GetAllSessionsFiltered2('available_sessions', true),
      this.sessionService.GetAllSessionsFiltered2('future_sessions', true),
      this.sessionService.GetAllSessionsFiltered2('today_sessions', true),
      this.sessionService.GetAllSessionsFiltered2('tomorrow_sessions', true),
    ]).subscribe((res) => {
      const allSlot: EventInput[] = [];
      const data: TeacherSession[] = [
        ...res[0].data as TeacherSession[],
        ...res[1].data as TeacherSession[],
        ...res[2].data as TeacherSession[],
        ...res[3].data as TeacherSession[],
      ];
      data?.forEach((event: TeacherSession) => {
        if (event.state === 1 || event.state === 2) {
          const title = (event?.user?.name && event?.course?.name) ? ` حصة مع ${event?.user?.name} - كورس - ${event?.course?.name}` : 'حصة محجوزة';
          const slot: EventInput = {
            id: event?.id + '',
            title: event.state === 1 ? this.getTitle(event) : title,
            start: this.datePipe.transform(new Date(getSafeDate(event?.start)), 'yyyy-MM-ddTHH:mm:ss') + '',
            isReserved: event.state === 2 || event.state === 3,
            end: this.datePipe.transform(new Date(getSafeDate(event?.end)), 'yyyy-MM-ddTHH:mm:ss') + '',
            editable: false,
            color: event.state === 1 ? '#0A4426' : '#CF8B40',
            state: event.state,
            joinState: event.join_state
          };
          allSlot.push(slot);
        }
      });
      this.calendarOptions.events = allSlot;
      this.loading = false;
    });
    // this.sessionService.GetAllSessions(true).subscribe((res) => {
    //   const allSlot: EventInput[] = [];
    //   const data: TeacherSession[] = [
    //     ...res?.data?.past_sessions as TeacherSession[],
    //     ...res?.data?.today_sessions as TeacherSession[],
    //     ...res?.data?.tomorrow_sessions as TeacherSession[],
    //     ...res?.data?.future_sessions as TeacherSession[],
    //   ];
    //   data?.forEach((event: TeacherSession) => {
    //     if (event.state === 1 || event.state === 2) {
    //       const title = (event?.user?.name && event?.course?.name) ? ` حصة مع ${event?.user?.name} - كورس - ${event?.course?.name}` : 'حصة محجوزة';
    //       const slot: EventInput = {
    //         id: event?.id + '',
    //         title: event.state === 1 ? 'حصة متاحة' : title,
    //         start: this.datePipe.transform(new Date(event?.start_iso_format), 'yyyy-MM-ddTHH:mm:ss') + '',
    //         isReserved: event.state === 2 || event.state === 3,
    //         end: this.datePipe.transform(new Date(event?.end), 'yyyy-MM-ddTHH:mm:ss') + '',
    //         editable: false,
    //         color: event.state === 1 ? '#0A4426' : '#CF8B40',
    //         state: event.state,
    //         joinState: event.join_state
    //       };
    //       allSlot.push(slot);
    //     }
    //   });
    //   this.calendarOptions.events = allSlot;
    //   this.loading = false;
    // });
  }

  getTitle(session: TeacherSession): string {
    if (!session) {
      return 'حصة متاحة';
    }
    if (session.suggested_user_id && session.suggested_user) {
      return ` حصة متاحة/مقترحة ل ${session?.suggested_user?.name}`;
    } else {
      return 'حصة متاحة';
    }
  }

  handleEventClick(clickInfo: EventClickArg): void {
    if (clickInfo.event.extendedProps.isReserved) {
      this.confirmService.open({
        title: 'لا يمكنك حذف هذة الحصة',
        cancelText: 'إلغاء'
      });
    }
    if (clickInfo.event.extendedProps?.state === 1) {
      this.confirmService.open({
        title: 'هل متاكد من حذف هذه الحصة',
        confirmText: 'تاكيد',
        cancelText: 'أغلاق'
      });
      this.confirmService.confirmed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.toasterService.showLoading('جاري التنفيذ...', false);
          this.sessionService.DeleteSession(clickInfo.event.id).subscribe((res) => {
            this.toasterService.hideLoading();
            this.toasterService.showSuccess('تم حذف المعاد بنجاح');
            clickInfo.event.remove();
          }, (error) => {
            this.toasterService.hideLoading();
            this.errorHandler.HandelAuthErrors(error.error.errors, error.status, error.message);
          });
        }
      });
      // this.bookSessionService.open(this.Teacher.id, parseInt(clickInfo.event.id, 10), this.Teacher.name);
      // this.bookSessionService.confirmed().subscribe((data) => {
      //   if (data.status) {
      //     location.reload();
      //   }
      // });
    }
  }

  handleDateSelect(selectInfo: DateSelectArg): void {
    const isValidDate = new Date().getTime() < selectInfo.start.getTime();
    if (isValidDate && ['timeGridWeek', 'timeGridDay'].includes(selectInfo.view.type)) {
      const calendarApi = selectInfo.view.calendar;
      checkSlotDuration(selectInfo.startStr, selectInfo.endStr);
      const {isValid, duration} = checkSlotDuration(selectInfo.startStr, selectInfo.endStr);
      if (isValid) {
        this.createSessionDialogueService.open({
          title: `هل متاكد من فتح هذه الحصة لمدة ${duration} دقيقة `,
          confirmText: 'تاكيد',
          cancelText: 'أغلاق',
        });
        this.createSessionDialogueService.confirmed().subscribe((confirmed: CreateSessionDialogueClose) => {
          if (confirmed.isConfirmed) {
            this.toasterService.showLoading('جاري حجز الحصة...', false);
            let sessionData: CreateSession = this.CreateSlot(selectInfo.startStr, duration);
            if (confirmed.selectedId > 0) {
              sessionData = {
                suggested_user_id: confirmed.selectedId,
                number_of_invitations: confirmed.invitationsNumber,
                ...this.CreateSlot(selectInfo.startStr, duration)
              };
              console.log('-> sessionData', sessionData);
            }
            this.sessionService.CreateSession(sessionData).subscribe((result) => {
              // calendarApi.unselect(); // clear date selection
              // calendarApi.addEvent({
              //   id: res?.data?.id ?? createEventId(),
              //   title: this.getTitle(res?.data as TeacherSession),
              //   start: selectInfo.startStr,
              //   end: selectInfo.endStr,
              //   allDay: selectInfo.allDay,
              //   isNew: true,
              //   isReserved: false,
              //   state: 1,
              //   join_state: 1,
              //   color: '#0A4426',
              // });
              // this.toasterService.hideLoading();
              // this.toasterService.showSuccess('تم فتح الحصة بنجاح');
              this.loading = true;
              calendarApi.unselect(); // clear date selection
              this.toasterService.hideLoading();
              this.toasterService.showSuccess('تم فتح الحصة بنجاح');
              forkJoin([
                this.sessionService.GetAllSessionsFiltered2('available_sessions', true),
                this.sessionService.GetAllSessionsFiltered2('future_sessions', true),
                this.sessionService.GetAllSessionsFiltered2('today_sessions', true),
                this.sessionService.GetAllSessionsFiltered2('tomorrow_sessions', true),
              ]).subscribe((res) => {
                const allSlot: EventInput[] = [];
                const data: TeacherSession[] = [
                  ...res[0].data as TeacherSession[],
                  ...res[1].data as TeacherSession[],
                  ...res[2].data as TeacherSession[],
                  ...res[3].data as TeacherSession[],
                ];
                data?.forEach((event: TeacherSession) => {
                  if (event.state === 1 || event.state === 2) {
                    const title = (event?.user?.name && event?.course?.name) ? ` حصة مع ${event?.user?.name} - كورس - ${event?.course?.name}` : 'حصة محجوزة';
                    const slot: EventInput = {
                      id: event?.id + '',
                      title: event.state === 1 ? this.getTitle(event) : title,
                      start: this.datePipe.transform(new Date(getSafeDate(event?.start)), 'yyyy-MM-ddTHH:mm:ss') + '',
                      isReserved: event.state === 2 || event.state === 3,
                      end: this.datePipe.transform(new Date(getSafeDate(event?.end)), 'yyyy-MM-ddTHH:mm:ss') + '',
                      editable: false,
                      color: event.state === 1 ? '#0A4426' : '#CF8B40',
                      state: event.state,
                      joinState: event.join_state
                    };
                    allSlot.push(slot);
                  }
                });
                this.calendarOptions.events = allSlot;
                this.loading = false;
              });
              // window.location.reload();
            }, (error) => {
              this.toasterService.hideLoading();
              this.errorHandler.HandelAuthErrors(error.error.errors, error.status, error.message);
            });
          }
        });
      } else {
        this.toasterService.hideLoading();
        this.toasterService.showFail('لا يمكنك فتح حصة اكتر من ساعتين');
      }
    } else {
      this.toasterService.showFail('توقيت الحصة خاطئ لا يمسح بفتح حصة فى هذا المعاد!');
    }
  }

  handleEvents(events: EventApi[]): void {
    this.currentEvents = events;
  }

  CreateSlot(startTime: string, duration: number = 30): CreateSession {
    return {
      start: this.datePipe.transform(startTime, 'yyyy-MM-dd HH:mm') + '',
      end: this.datePipe.transform(new Date(new Date(startTime).setMinutes(new Date(startTime).getMinutes() + duration)),
        'yyyy-MM-dd HH:mm') + '',
    };
  }
}
