import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[defaultImageOnError]'
})
export class DefaultImageOnErrorDirective {
  @Input() defaultImageOnError!: string;

  @HostListener('error', ['$event'])
  handleError(event: ErrorEvent): void {
    (event.target as HTMLImageElement).src = this.defaultImageOnError;
  }
}
