import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from '@shared/components/common-components/not-found/not-found.component';
import {GuestGuardService} from '@core/@http/guards/gest.guard';
import {AuthGuardService} from '@core/@http/guards/auth.guard';
import {ForceLoginComponent} from '@shared/components/other/force-login/force-login.component';

const routes: Routes = [
  /*--------------------------- Static Pages -------------------------------*/
  {
    path: '',
    redirectTo: 'my-account/my-schedule',
    pathMatch: 'full',
  },
  /*--------------------------- Dashboards Pages -------------------------------*/
  {
    path: 'my-account/my-schedule',
    loadChildren: () => import('./modules/my-schedule/my-schedule.module').then((m) => m.MyScheduleModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/available-sessions',
    loadChildren: () => import('./modules/available-sessions/available-sessions.module').then((m) => m.AvailableSessionsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/my-profile',
    loadChildren: () => import('./modules/my-profile/my-profile.module').then((m) => m.MyProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/my-insights',
    loadChildren: () => import('./modules/my-insights/my-insights.module').then((m) => m.MyInsightsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/my-courses',
    loadChildren: () => import('./modules/my-courses/my-courses.module').then((m) => m.MyCoursesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/all-rates',
    loadChildren: () => import('./modules/student-rates/student-rates.module').then((m) => m.StudentRatesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/my-students',
    loadChildren: () => import('./modules/my-students/my-students.module').then((m) => m.MyStudentsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/my-appointments-invitations',
    loadChildren: () => import('./modules/my-appointments-invitations/my-appointments-invitations.module').then((m) => m.MyAppointmentsInvitationsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/group-events',
    loadChildren: () => import('./modules/goup-events/group-events.module').then((m) => m.GroupEventsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-calendar',
    loadChildren: () => import('./modules/my-calendar/my-calendar.module').then((m) => m.MyCalendarModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-messages',
    loadChildren: () => import('./modules/messages/messages.module').then((m) => m.MessagesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'certificates-generation',
    loadChildren: () => import('./modules/generating-certificates/generating-certificates.module').then(
      (m) =>
        m.GeneratingCertificatesModule
    ),
    canActivate: [AuthGuardService],
  },
  /*--------------------------- Auth Pages -------------------------------*/
  {
    path: 'user',
    loadChildren: () => import('./modules/@auth/auth.module').then((m) => m.AuthModule),
    canActivate: [GuestGuardService],
  },
  {
    path: 'tests/certificate',
    loadChildren: () => import('./modules/tests/certificate/certificate.module').then((m) => m.CertificateModule),
  },
  /*--------------------------- Automatic Login -------------------------------*/
  {
    path: 'force-login',
    component: ForceLoginComponent,
  },
  /*--------------------------- WildCard Page -------------------------------*/
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
