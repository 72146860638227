<footer>
  <div class="container">
    <app-main-our-stats></app-main-our-stats>
    <div class="holder">
      <section class="copy__rights">
        جميع الحقوق محفوظة لقران اونلاين
      </section>
      <section class="social__media">
        <a aria-label="instagram Page" class="social__media__links"
           href="https://www.instagram.com/qoraan_online/?fbclid=IwAR08UrENTL_89t53Q2cm00TJzFiAgYP0T48EUWWIKvH2jWYopihPYXzL-tg"
           rel="noreferrer noopener"
           target="_blank">
          <svg height="24" viewBox="0 0 23 24" width="23" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.5,2c3.125,0,3.514.012,4.74.072a8.165,8.165,0,0,1,2.792.558A5.589,5.589,0,0,1,23.07,4.014,5.921,5.921,0,0,1,24.4,6.14a9.2,9.2,0,0,1,.535,2.914C24.985,10.333,25,10.74,25,14s-.012,3.667-.069,4.946A9.209,9.209,0,0,1,24.4,21.86a5.891,5.891,0,0,1-1.326,2.126,5.635,5.635,0,0,1-2.038,1.384,8.187,8.187,0,0,1-2.792.558c-1.226.056-1.616.072-4.74.072s-3.514-.012-4.74-.072a8.193,8.193,0,0,1-2.792-.558A5.606,5.606,0,0,1,3.93,23.986,5.916,5.916,0,0,1,2.6,21.86a9.17,9.17,0,0,1-.535-2.914C2.015,17.667,2,17.26,2,14s.012-3.667.069-4.946A9.17,9.17,0,0,1,2.6,6.14,5.887,5.887,0,0,1,3.93,4.014,5.614,5.614,0,0,1,5.968,2.63,8.159,8.159,0,0,1,8.76,2.072C9.986,2.016,10.375,2,13.5,2Zm0,6a5.879,5.879,0,0,0-5.75,6,5.879,5.879,0,0,0,5.75,6,5.879,5.879,0,0,0,5.75-6A5.879,5.879,0,0,0,13.5,8Zm7.475-.3a1.439,1.439,0,1,0-1.437,1.5A1.47,1.47,0,0,0,20.975,7.7ZM13.5,10.4A3.527,3.527,0,0,1,16.95,14a3.527,3.527,0,0,1-3.45,3.6A3.527,3.527,0,0,1,10.05,14,3.527,3.527,0,0,1,13.5,10.4Z"
              data-name="Path 3010"
              fill="#0c8849"
              id="Path_3010" transform="translate(-2 -2)"/>
          </svg>
        </a>
        <a aria-label="twitter Page" class="social__media__links"
           href="https://twitter.com/Qoraan_online?fbclid=IwAR2WBdJoNIv3Ykv7ZHDtgibK57Kv1LewQX33LEoWMc1JNYGNNRLpnCYdujw"
           rel="noreferrer noopener">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.429,128H2.571A2.572,2.572,0,0,0,0,130.571v18.857A2.572,2.572,0,0,0,2.571,152H21.429A2.572,2.572,0,0,0,24,149.429V130.571A2.572,2.572,0,0,0,21.429,128Zm-2.62,8.507c.011.15.011.305.011.455a9.928,9.928,0,0,1-10,10,9.95,9.95,0,0,1-5.395-1.575,7.4,7.4,0,0,0,.846.043,7.044,7.044,0,0,0,4.361-1.5,3.518,3.518,0,0,1-3.284-2.437,3.787,3.787,0,0,0,1.586-.064,3.514,3.514,0,0,1-2.813-3.45v-.043a3.512,3.512,0,0,0,1.586.445,3.506,3.506,0,0,1-1.564-2.925,3.47,3.47,0,0,1,.477-1.773,9.976,9.976,0,0,0,7.243,3.675,3.521,3.521,0,0,1,5.995-3.209,6.885,6.885,0,0,0,2.229-.846,3.5,3.5,0,0,1-1.543,1.934,6.992,6.992,0,0,0,2.025-.546A7.4,7.4,0,0,1,18.809,136.507Z"
              fill="#0c8849"
              id="twitter-square" transform="translate(0 -128)"/>
          </svg>
        </a>
        <a aria-label="facebook Page" class="social__media__links" href="https://www.facebook.com/Qoraanonline"
           rel="noreferrer noopener"
           target="_blank">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M54.675,32H33.325A1.325,1.325,0,0,0,32,33.325v21.35A1.325,1.325,0,0,0,33.325,56H44V46.571H41.131V43.143H44V40.411a4.559,4.559,0,0,1,4.919-4.787c1.325,0,2.75.1,3.081.144V39H49.794C48.287,39,48,39.717,48,40.767v2.376h3.589l-.469,3.429H48V56h6.675A1.325,1.325,0,0,0,56,54.675V33.325A1.325,1.325,0,0,0,54.675,32Z"
              fill="#0c8849"
              id="logo-facebook" transform="translate(-32 -32)"/>
          </svg>
        </a>
        <a aria-label="linked in Page" class="social__media__links"
           href="https://www.linkedin.com/company/qoraanonline/?fbclid=IwAR36NBMBQMLTUM-O8QbgZG6d73EglrQVCuni9UHFLsCnLT3kbZy0345QIH8"
           rel="noreferrer noopener"
           target="_blank">
          <svg id="Layer_1" style="enable-background:new 0 0 382 382;" version="1.1" viewBox="0 0 382 382" x="0px"
               xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
<path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
	C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
	H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
	c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
	s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
	c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
	c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
	c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
	L341.91,330.654L341.91,330.654z" style="fill:#0C8849;"/>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>
        </a>
      </section>
      <section class="privacy__policy">
        <a class="privacy__policy__link" href="#" target="_blank">سياسة الخصوصية</a>
      </section>
    </div>
  </div>
</footer>
