import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToasterService} from '@shared/services/toastr.service';
import {MyStudent} from '@core/@data/CoursesData/Session';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MyStudentsService} from '@app/modules/my-students/services/my-students.service';
import {ErrorHandlingService} from '@shared/services/error-handling.service';
import {
  CreateSessionDialogueClose
} from '@app/modules/my-calendar/components/create-session-dialouge/create-session-dialogue.service';

@Component({
  selector: 'app-create-session-dialogue',
  templateUrl: './create-session-dialogue.component.html',
  styleUrls: ['./create-session-dialogue.component.scss']
})
export class CreateSessionDialogueComponent implements OnInit {
  studentCtrl = new FormControl();
  invitationsNumber = new FormControl(1);
  filteredStudents: Observable<MyStudent[]> = of([]);
  myStudents: MyStudent[] = [];
  loadingStudentsStatus: 'loading' | 'loaded' | 'error' = 'loading';
  selectedId = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      cancelText: string;
      confirmText: string;
      message: string;
      title: string;
    },
    private myStudentsService: MyStudentsService,
    private mdDialogRef: MatDialogRef<CreateSessionDialogueComponent>,
    private toasterService: ToasterService,
    private errorHandler: ErrorHandlingService
  ) {
  }

  ngOnInit(): void {
    this.myStudentsService.GetAllStudentsWithoutFilters().subscribe(
      (res) => {
        this.myStudents = res?.data?.students as MyStudent[];
        this.filteredStudents = this.studentCtrl.valueChanges
          .pipe(
            startWith(''),
            map((student) => {
              return student ? this.filterStates(student) : this.myStudents.slice();
            })
          );
        this.loadingStudentsStatus = 'loaded';
      },
      (error) => {
        this.loadingStudentsStatus = 'error';
        this.errorHandler.HandelAuthErrors(error?.error.errors, error.status, error.message);
      });
  }

  selectStudent($event: any) {
    console.log('-> $eventsss', $event);
    if ($event.source?.value) {
      const selectedId = this.myStudents.filter((student) => {
        return student.name.toLowerCase() == ($event.source?.value?.toLowerCase());
      });
      if (Array.isArray(selectedId) && selectedId.length > 0) {
        console.log('-> selectedId', selectedId[0].id);
        this.selectedId = selectedId[0].id;
      }
    }
  }

  filterStates(value: string): MyStudent[] {
    console.log('-> value', value);
    const filterValue = value.toLowerCase();

    return this.myStudents.filter(student => student.name.toLowerCase().includes(filterValue));
  }

  public cancel(): void {
    this.close({
      selectedId: this.selectedId,
      invitationsNumber: this.invitationsNumber.value,
      isConfirmed: false
    });
  }

  public close(data: CreateSessionDialogueClose): void {
    this.mdDialogRef.close({
      selectedId: data.selectedId,
      invitationsNumber: data.invitationsNumber,
      isConfirmed: data.isConfirmed
    });
  }

  public confirm(): void {
    this.close({
      selectedId: this.selectedId,
      invitationsNumber: this.invitationsNumber.value,
      isConfirmed: true
    });
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close({
      selectedId: this.selectedId,
      invitationsNumber: this.invitationsNumber.value,
      isConfirmed: false
    });
  }

  @HostListener('keydown.enter')
  public onEnter(): void {
    this.close({
      selectedId: this.selectedId,
      invitationsNumber: this.invitationsNumber.value,
      isConfirmed: true
    });
  }
}
