import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-our-stats',
  templateUrl: './our-stats.component.html',
  styleUrls: ['./our-stats.component.scss']
})
export class OurStatsComponent implements OnInit {
  OurStats: { title: string; count: number }[] = [
    {title: 'طالب', count: 5000},
    {title: 'حلقة شهرية', count: 5000},
    {title: 'معلم', count: 100},
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  NumberToDigits(value: number): string[] {
    return value.toString().split('');
  }

}
