import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {Message, SimpleChatData, TicketChatData, UnReadMessagesCount} from '@core/@data/General/Chat';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  Url = `${env.ApiUrl}/teacher/chat`;

  constructor(
    private http: HttpClient,
  ) {
  }

  GetAllChats(): Observable<ApiResponse<TicketChatData[]>> {
    return this.http.get<ApiResponse<TicketChatData[]>>(`${this.Url}/tickets`);
  }

  ChatWithFilter(key: string): Observable<ApiResponse<TicketChatData[]>> {
    return this.http.get<ApiResponse<TicketChatData[]>>(`${this.Url}/tickets?key=${key}`);
  }

  GetMessagesByTicketId(id: number): Observable<ApiResponse<TicketChatData>> {
    return this.http.get<ApiResponse<TicketChatData>>(`${this.Url}/messages/${id}/ticket`);
  }

  CreateChatByTicketId(id: number, data: FormData): Observable<ApiResponse<Message>> {
    return this.http.post<ApiResponse<Message>>(`${this.Url}/messages/${id}/create`, data);
  }

  CreateChatByStudentId(id: number): Observable<ApiResponse<SimpleChatData>> {
    return this.http.post<ApiResponse<SimpleChatData>>(`${this.Url}/tickets/${id}/create`, {});
  }

  GetUnReadMessagesCount(): Observable<UnReadMessagesCount> {
    return this.http.get<UnReadMessagesCount>(`${this.Url}/unread-messages-count`);
  }
}// End of Class
