import {LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {FooterComponent} from '@core/components/layouts/footer/footer.component';
import {NavBarComponent} from '@core/components/layouts/nav-bar/nav-bar.component';
import {AuthNavbarComponent} from '@core/components/layouts/nav-bar/auth-navbar/auth-navbar.component';
import {GuestNavbarComponent} from '@core/components/layouts/nav-bar/guest-navbar/guest-navbar.component';
import {
  SmallNavLinkComponent
} from '@core/components/layouts/nav-bar/components/small-nav-link/small-nav-link.component';
import {NavLinkComponent} from '@core/components/layouts/nav-bar/components/nav-link/nav-link.component';
import {OurStatsComponent} from '@core/components/our-stats/our-stats.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from '@core/@http/interceptors/token.interceptor';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import ar from '@angular/common/locales/ar';
import {HotToastModule} from '@ngneat/hot-toast';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../../environments/environment';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {UpcomingSessionModule} from '@shared/modules/upcoming-session/upcoming-session.module'; // <-- include ScrollHooks

registerLocaleData(ar);


@NgModule({
  declarations: [
    FooterComponent,
    NavBarComponent,
    AuthNavbarComponent,
    GuestNavbarComponent,
    SmallNavLinkComponent,
    NavLinkComponent,
    OurStatsComponent
  ],
  imports: [
    RouterModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    BrowserAnimationsModule,
    HotToastModule.forRoot({
      position: 'top-right',
      reverseOrder: false,
      dismissible: true,
      autoClose: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.production,
      registrationStrategy: 'registerWhenStable:10000'
    }),
    SharedModule,
    UpcomingSessionModule,
  ],
  exports: [NavBarComponent],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'ar-Eg'},
    {provide: LOCALE_ID, useValue: 'ar-EGP'},
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
