<content-loader *ngIf="type === 'course'"
                [rtl]="true"
                [speed]="speed"
                backgroundColor="#cfcfcf"
                foregroundColor="#ecebeb"
                viewBox="0 0 425 700">
  <svg:rect height="550" rx="0" ry="0" width="2" x="0" y="53"/>
  <svg:rect height="550" rx="0" ry="0" width="2" x="400" y="55"/>
  <svg:rect height="2" rx="0" ry="0" width="400" x="0" y="600"/>
  <svg:circle cx="200" cy="200" r="60"/>
  <svg:rect height="41" rx="0" ry="0" width="400" x="0" y="53"/>
  <svg:rect height="9" rx="0" ry="0" width="250" x="75" y="300"/>
  <svg:rect height="9" rx="0" ry="0" width="250" x="75" y="350"/>
  <svg:rect height="9" rx="0" ry="0" width="250" x="75" y="400"/>
  <svg:rect height="9" rx="0" ry="0" width="250" x="75" y="450"/>
  <svg:rect height="50" rx="0" ry="0" width="146" x="130" y="500"/>
</content-loader>
<content-loader *ngIf="type === 'teacher'"
                [rtl]="true"
                [speed]="speed"
                backgroundColor="#cfcfcf"
                foregroundColor="#ecebeb"
                viewBox="0 0 425 500">
  <svg:rect height="400" rx="0" ry="0" width="2" x="0" y="53"/>
  <svg:rect height="400" rx="0" ry="0" width="2" x="400" y="55"/>
  <svg:rect height="2" rx="0" ry="0" width="400" x="0" y="450"/>
  <svg:circle cx="200" cy="200" r="60"/>
  <svg:rect height="41" rx="0" ry="0" width="400" x="0" y="53"/>
  <svg:rect height="9" rx="0" ry="0" width="100" x="150" y="290"/>
  <svg:rect height="9" rx="0" ry="0" width="150" x="125" y="325"/>
  <svg:rect height="9" rx="0" ry="0" width="200" x="100" y="360"/>
  <svg:rect height="50" rx="0" ry="0" width="146" x="130" y="425"/>
</content-loader>
<content-loader
  *ngIf="type === 'sidebar'"
  [rtl]="true"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 300 615"
>
  <svg:rect height="1" rx="0" ry="0" width="0" x="79" y="20"/>
  <svg:rect height="400" rx="0" ry="0" width="3" x="4" y="1"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="4" y="400"/>
  <svg:rect height="3" rx="0" ry="0" width="5" x="158" y="400"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="5" y="1"/>
  <svg:rect height="400" rx="0" ry="0" width="3" x="296" y="1"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="5" y="60"/>
  <svg:rect height="23" rx="0" ry="0" width="129" x="22" y="20"/>
  <svg:rect height="9" rx="4" ry="4" width="81" x="35" y="76"/>
  <svg:rect height="18" rx="4" ry="4" width="18" x="271" y="22"/>
  <svg:rect height="9" rx="4" ry="4" width="81" x="186" y="76"/>
  <svg:rect height="44" rx="0" ry="0" width="2" x="150" y="63"/>
  <svg:rect height="3" rx="0" ry="0" width="144" x="6" y="104"/>
  <svg:rect height="1" rx="0" ry="0" width="145" x="152" y="106"/>
  <svg:rect height="31" rx="4" ry="4" width="243" x="28" y="127"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="62" y="188"/>
  <svg:circle cx="39" cy="197" r="10"/>
  <svg:circle cx="39" cy="247" r="10"/>
  <svg:circle cx="39" cy="297" r="10"/>
  <svg:circle cx="39" cy="347" r="10"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="64" y="237"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="65" y="287"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="64" y="337"/>
</content-loader>
