import {Injectable} from '@angular/core';
import {HotToastService} from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private toast: HotToastService) {
  }

  showSuccess(msg: string, time = 3000): void {
    this.toast.success(msg, {
      duration: time,
    });
  }


  ShowWarning(msg: string): void {
    this.toast.warning(msg, {
      duration: 3000,
    });
  }

  showFail(msg: string, duration = 3000): void {
    this.toast.error(msg, {
      duration,
    });
  }

  showLoading(message: string, isDissemble: boolean = true): void {
    this.toast.loading(message, {
      position: 'top-center',
      id: 'loading',
      dismissible: isDissemble
    });
  }

  hideLoading(): void {
    this.toast.close('loading');
  }
}
