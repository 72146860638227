import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {LocalstorageService} from '@core/@http/services/localstorage.service';
import {TeacherAuthService} from '@core/@http/services/teacher-auth.service';

@Component({
  selector: 'app-force-login',
  template: `
    <app-loader *ngIf="loading"></app-loader>`,
  styleUrls: [],
})
export class ForceLoginComponent implements OnInit {

  loading = true;

  constructor(
    private authService: TeacherAuthService,
    private localStorageService: LocalstorageService,
    private route: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((res: ParamMap) => {
      this.localStorageService.ClearStorage();
      if (res?.has('_token')) {
        this.localStorageService.setState('token', res.get('_token'));
        this.authService.profileUser2();
      } else {
        this.router.navigate(['/']).then();
      }
    });
  }

}
