import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {TeacherSession, UpcomingSession} from '@core/@data/CoursesData/Session';
import {
  UpcomingSessionUpdateZoomDataComponent
} from '@shared/modules/update-zoom-data/upcoming-session-update-zoom-data.component';


@Injectable({
  providedIn: 'root'
})
export class UpcomingSessionUpdateZoomDataService {
  dialogRef!: MatDialogRef<UpcomingSessionUpdateZoomDataComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(session: TeacherSession | UpcomingSession): void {
    this.dialogRef = this.dialog.open(UpcomingSessionUpdateZoomDataComponent, {
      data: {
        session
      },
    });
  }

  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
}

