<div class="header">
  <h1 mat-dialog-title>{{ data!.title }}</h1>
</div>
<div *ngIf="data!.message !== '' && data!.message && data!.isSessionLink === false" class="body__content"
     mat-dialog-content>
  <p class="dialog-message" dir="rtl">{{ data!.message }}</p>
</div>
<div *ngIf="data!.message !== '' && data!.message && data!.isSessionLink === true" class="body__content"
     mat-dialog-content>
  <p class="dialog-message">رابط الحصة</p>
  <p class="dialog-message">قم الضغط على الرابط أدناه</p>
  <p class="dialog-message">فى حالة إن واجهتك مشكلة فى دخول الحصة ( يمكنك نسخ الرابط ولصقه فى صفحة جديدة داخل المتصفح
    )</p>
  <div class="session__content">
    <button (cdkCopyToClipboardCopied)="CodeCopied($event)"
            [cdkCopyToClipboard]="data!.message"
            class="copy__to__clipboard" mat-icon-button type="button">
      <svg fill="#CF8B40" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path
          d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
      </svg>
    </button>
    <a [href]="data!.message" class="session__link" target="_blank">{{data!.message}}</a>
  </div>
</div>
<section class="actions__container" mat-dialog-actions>
  <button (click)="confirm()" *ngIf="data!.confirmText && data!.confirmText !== ''" class="button raised__button_white">
    {{ data!.confirmText }}
  </button>
  <button (click)="cancel()" *ngIf="data?.cancelText && data?.cancelText !== ''" class="button raised__button-special">
    {{ data!.cancelText }}
  </button>
</section>
