<section class="navbar__main__toolbar">
  <mat-toolbar class="main__toolbar" dir="rtl">
    <!-- Logo   -->
    <div class="d-flex align-items-center justify-content-start">
      <a class="navbar-brand" routerLink="/my-account/my-schedule">
        <img alt="logo" class="main__logo__img" src="/assets/images/logo/logo wide green (1).png"></a>
    </div>
    <div class="fxMainBar">
      <app-main-nav-link [isExact]="false" [link]="'/my-account/my-schedule'" [name]="'حسابى '"></app-main-nav-link>
      <app-main-nav-link [isExact]="true" [link]="'/my-calendar'" [name]="'مواعيدى '"></app-main-nav-link>
      <!--      <app-main-nav-link [isExact]="true" [link]="'/my-messages'" [name]="' الرسائل '" matBadge="1"></app-main-nav-link>-->
      <a
        [matBadgeHidden]="unReadMessagesCount === 0"
        [matBadge]="unReadMessagesCount"
        [routerLinkActiveOptions]="{exact:true}"
        [routerLink]="'/my-messages'"
        class="main__nav--link nav__link-colors nav__link__white"
        matBadgePosition="above before"
        routerLinkActive="active">الرسائل</a>
    </div>
    <span class="example-spacer"></span>
    <div class="left__part">
      <div class="notifications__container">
        <button
          [matBadgeHidden]="unReadNotificationsCount === 0"
          [matBadge]="unReadNotificationsCount"
          [matMenuTriggerFor]="Notifications"
          class="notifications__btn">
          <svg fill="#707070" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path
              d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/>
          </svg>
        </button>
        <div class="notifications__menu">
          <mat-menu #Notifications="matMenu" class="notificationsMenu" xPosition="before" yPosition="below">
            <div class="menu-container">
              <p class="mat__menu__header">الإشعارات</p>
              <mat-selection-list *ngIf="notifications!.length>0" [multiple]="false"
                                  class="menu__navbar--list">
                <mat-list-option (click)="NotificationRead(notification)"
                                 *ngFor="let notification of notifications">
                  <span [innerHTML]="(notification.data.message|slice:0:45) + ' ...'"
                        class="notification__body">...</span>
                </mat-list-option>
              </mat-selection-list>
              <p *ngIf="notifications!.length==0" class="mat__menu__no--data text-center">
                لا يوجد إشعارات جديدة
              </p>
              <a [routerLink]="['/notifications' ]"
                 class="mat__menu__footer">
                مشاهدة الكل
              </a>
            </div>
          </mat-menu>
        </div>
      </div>
      <div class="profile__data__container">
        <div class="content">
          <img [alt]="userName"
               [defaultImage]="userImage"
               [errorImage]="userImage"
               [lazyLoad]="userImage"
               alt=""
               class="img">
          <p>{{userName | nameSplit: " ":2 | slice: 0:15 }}</p>
        </div>
      </div>

    </div>

    <!-- Toggle Button SideNav   -->
    <div class="fxButton">
      <button
        (click)="sidenav.toggle()"
        aria-label="Example icon-button with menu icon"
        class="example-icon" color="primary" mat-mini-fab>
        <svg fill="#FFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
        </svg>
      </button>
    </div>
    <!-- Ends of Toggle Button SideNav   -->
  </mat-toolbar>
  <!--  <app-upcoming-session></app-upcoming-session>-->
  <!-- Container -->
  <mat-sidenav-container class="example-container fxFill" dir="rtl">
    <!--  Side Nav  -->
    <mat-sidenav #sidenav class="flex-column">
      <div class="side__user__data">
        <!--       USer Image         -->
        <a class="main__nav--link">
          <img
            [alt]="userName"
            [defaultImage]="userImage"
            [errorImage]="userImage"
            [lazyLoad]="userImage"
            class="rounded-circle"
            height="50px"
            width="50px"
          />
        </a>
        <!--       User Data         -->
        <div class="navbar__userdata">
          <p class="navbar__userdata--heading">
            {{ userName | nameSplit: " ":2 | slice: 0:15 }}
          </p>
        </div>
      </div>
      <!--  Teacher Routes      -->
      <div class="sidebar__content-container">
        <!--  جدول الحصص      -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/my-schedule'">
          جدول الحصص
        </app-main-small-nav-link>
        <!--  الحصص المتاحة     -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/available-sessions'">
          الحصص المتاحة
        </app-main-small-nav-link>
        <!--  مواعيدى   -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-calendar'">
          مواعيدى
        </app-main-small-nav-link>
        <!--  دواتى      -->
        <!--        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/my-courses'">-->
        <!--          دوراتى-->
        <!--        </app-main-small-nav-link>-->
        <!--  الملف الشخصى      -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/my-profile'">
          الملف الشخصى
        </app-main-small-nav-link>
        <!--  الرسائل    -->
        <a
          [color]="'primary'"
          [matBadgeHidden]="unReadMessagesCount === 0"
          [matBadge]="unReadMessagesCount"
          [routerLinkActiveOptions]="{exact:true}"
          [routerLink]="'/my-messages'"
          class="main__navbar--side--links"
          mat-button
          matBadgePosition="above after"
          routerLinkActive="sideActive">
          الرسائل
        </a>
        <!-- تقييمات الطلاب      -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/my-appointments-invitations'">
          دعوات الحصص
        </app-main-small-nav-link>
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/all-rates'">
          تقييمات الطلاب
        </app-main-small-nav-link>
        <!-- طلابى    -->
        <app-main-small-nav-link [color]="'primary'" [link]="'/my-account/my-students'">
          طلابي
        </app-main-small-nav-link>
      </div>
      <!--      LogOut Button         -->
      <a (click)="logout()"
         class="main__navbar--side--links"
         color="primary"
         mat-button>
        <svg enable-background="new 0 0 24 24" fill="#FCB040" height="24" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <g>
            <rect fill="none" height="24" width="24"/>
          </g>
          <g>
            <path
              d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/>
          </g>
        </svg>
        تسجيل الخروج</a>
    </mat-sidenav>
    <!-- Content  -->
    <mat-sidenav-content>
      <ng-content></ng-content>
      <router-outlet></router-outlet>
      <app-scroll-to-top></app-scroll-to-top>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>
