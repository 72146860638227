import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {
  UpcomingSessionUpdateZoomDataComponent
} from '@shared/modules/update-zoom-data/upcoming-session-update-zoom-data.component';


@NgModule({
  declarations: [
    UpcomingSessionUpdateZoomDataComponent
  ],
  exports: [
    UpcomingSessionUpdateZoomDataComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class UpdateZoomDataModule {
}
