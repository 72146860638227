import {Component, OnInit} from '@angular/core';
import {SessionService} from '@core/@http/services/session.service';
import {UpcomingSession} from '@core/@data/CoursesData/Session';
import {convertTimeToNumber} from '@shared/functions/time-functions';
import {ToasterService} from '@shared/services/toastr.service';
import {OpenUrlService} from '@shared/services/open-url.service';
import {ErrorHandlingService} from '@shared/services/error-handling.service';
import {timer} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {
  UpcomingSessionUpdateZoomDataService
} from '@shared/modules/update-zoom-data/upcoming-session-update-zoom-data.service';

export function CalcTime(timeInMile: number): { seconds: number; minutes: number; days: number; hours: number; } {
  // let time = (timeInMile + 30000) / 1000;
  let time = timeInMile;
  let days, hours, minutes, seconds;
  days = Math.floor(time / 86400);
  time -= days * 86400;
  hours = Math.floor(time / 3600) % 24;
  time -= hours * 3600;
  minutes = Math.floor(time / 60) % 60;
  time -= minutes * 60;
  seconds = Math.floor(time % 60);
  return {days, hours, minutes, seconds};
}

export const PeriodTOStart = 0;

@Component({
  selector: 'app-upcoming-session',
  templateUrl: './upcoming-session.component.html',
  styleUrls: ['./upcoming-session.component.scss']
})
export class UpcomingSessionComponent implements OnInit {
  upcomingSession!: UpcomingSession;
  loading = true;
  barContent = 'جاري تحميل بيانات الحصة القادمة ...';
  sessionStatus: 'default' | 'upcoming' | 'started' | 'ended' = 'default';

  constructor(
    private sessionService: SessionService,
    private toasterService: ToasterService,
    private openUrlService: OpenUrlService,
    private errorHandler: ErrorHandlingService,
    private upcomingSessionUpdateZoomDataService: UpcomingSessionUpdateZoomDataService,
  ) {
  }

  ngOnInit(): void {
    this.sessionService.GetUpcomingSession().subscribe((res) => {
      this.upcomingSession = res.data as UpcomingSession;
      timer(0, 60000).pipe(
        takeWhile(() => {
          return this.sessionStatus !== 'ended';
        })
      ).subscribe(() => {
        this.calculateTime();
      });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.barContent = error?.error?.errors?.message[0] ?? 'لا يوجد مواعيد قادمة .';
    });
  }

  enteringSession(): void {
    if (
      this.upcomingSession.meeting_room
      && this.upcomingSession.meeting_room?.attendee_link
      && this.upcomingSession.meeting_room?.attendee_link !== ''
    ) {
      this.toasterService.showLoading('جاري التنفيذ...');
      this.sessionService.JoinSession(this.upcomingSession?.id).subscribe(
        (res) => {
          this.toasterService.hideLoading();
          this.openUrlService.OpenUrl(res?.url as string);
        },
        (error) => {
          this.toasterService.hideLoading();
          this.errorHandler.HandelAuthErrors(error.error.errors, error.status, error.message);
          this.InsertZoomLinkManual(this.upcomingSession);
        });
    } else {
      this.InsertZoomLinkManual(this.upcomingSession);
    }
  }

  InsertZoomLinkManual(session: UpcomingSession): void {
    this.upcomingSessionUpdateZoomDataService.open(session);
    this.upcomingSessionUpdateZoomDataService.confirmed().subscribe();
  }

  calculateTime(): void {
    const isStarted =
      ((this.upcomingSession.minutes_to_start >= PeriodTOStart) &&
        (this.upcomingSession.minutes_to_start <= convertTimeToNumber(this.upcomingSession.duration)))
      ||
      (this.upcomingSession.force_start === 1);
    if (!isStarted) {
      this.sessionStatus = 'upcoming';
    } else {
      if (this.upcomingSession.minutes_to_start <= convertTimeToNumber(this.upcomingSession.duration)) {
        this.sessionStatus = 'started';
      } else {
        this.sessionStatus = 'ended';
      }
    }
  }
}
