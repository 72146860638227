import {Component, OnInit, ViewChild} from '@angular/core';

import {AlertService} from '@shared/services/alert.service';
import {ChatService} from '@core/@http/services/chat.service';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {TeacherAuthService} from '@core/@http/services/teacher-auth.service';
import {interval} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ConfirmDialogService} from '@shared/components/common-components/confirm-dialouge/confirm.service';
import {NotificationsService} from '@core/@http/services/notifications.service';
import {Notification} from '@core/@data/Notification';

@Component({
  selector: 'app-main-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.scss'],
})
export class AuthNavbarComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  userName = '';
  userImage = '/assets/images/default-logos/login.svg';
  unReadMessagesCount = 0;
  unReadNotificationsCount = 0;
  notifications: Notification[] = [];

  constructor(
    private router: Router,
    private teacherAuthService: TeacherAuthService,
    private chatService: ChatService,
    private alertService: AlertService,
    private confirmDialogService: ConfirmDialogService,
    private notificationsService: NotificationsService,
    private authService: TeacherAuthService,
  ) {
  }

  ngOnInit(): void {
    this.getNotificationsCount();
    this.getAllNotifications();
    this.chatService.GetUnReadMessagesCount().subscribe((res) => {
      this.unReadMessagesCount = res?.count as number;
    });
    this.router.events.subscribe(() => {
      this.sidenav?.close().then();
    });
    this.authService.userDataBehaviorSubject.subscribe((res) => {
      this.userName = res?.name?.ar as string;
      this.userImage = (res?.img as string) && (res?.img as string) !== '' ? (res?.img as string) : (res?.gender === 1 ?
        './assets/images/default-logos/login.svg' :
        './assets/images/default-logos/Group 11524.svg');
    });
    this.teacherAuthService.userDataBehaviorSubject.subscribe((res) => {
      this.userName = res?.name?.ar as string;
      this.userImage = res?.img as string;
    });
    interval(30000)
      .pipe(
        switchMap((data) => {
          return this.chatService.GetUnReadMessagesCount();
        })
      )
      .subscribe((res) => {
        if (res?.count > this.unReadMessagesCount) {
          this.alertService.playAudio();
          this.unReadMessagesCount = res?.count as number;
        }
      });
  }

  logout(): void {
    this.teacherAuthService.Logout();
  }

  getAllNotifications(): void {
    this.notificationsService.GetAllWithPagination(1).subscribe((res) => {
      this.notifications = res?.data as Notification[];
    });
  }

  getNotificationsCount(): void {
    this.notificationsService.UnReadCount().subscribe((res) => {
      this.unReadNotificationsCount = res?.count as number;
    });
  }

  NotificationRead(notification: Notification): void {
    this.confirmDialogService.open({
      title: '',
      message: notification.data.message,
      confirmText: '',
      cancelText: 'إغلاق'
    });
    this.notificationsService.MarkAsRead([notification.id]).subscribe((res) => {
      this.getNotificationsCount();
    });
  }
}
