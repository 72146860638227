import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getPlanStrings'
})
export class GetPlanStringsPipe implements PipeTransform {

  transform(value: string, isDuration: boolean): string {
    if (isDuration) {
      return this.GetPlanDuration(value);
    } else {
      return '';
    }
  }

  GetPlanDuration(value: string = ''): string {
    if (value === '00:30') {
      return 'نصف ساعة للمحاضرة';
    }
    if (value === '01:00') {
      return 'ساعة للمحاضرة';
    }
    if (value === '00:60') {
      return 'ساعة للمحاضرة';
    } else {
      return 'نصف ساعة للمحاضرة';
    }
  }
}
