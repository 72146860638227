import {Pipe, PipeTransform} from '@angular/core';
import {convertTimeToNumber} from '@shared/functions/time-functions';

@Pipe({
  name: 'timesDiff',
  pure: true
})
export class TimesDiffPipe implements PipeTransform {
  transform(value: number, duration: string): boolean {
    return (value >= -3) && (value <= convertTimeToNumber(duration));
  }
}
