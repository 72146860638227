export function convertTimeToNumber(time: string): number {
  const timeArray = time.split(':');
  const hours = Number(timeArray[0]);
  const minutes = Number(timeArray[1]);
  return hours * 60 + minutes;
}

export function formatDuration(duration: number): string {
  switch (duration) {
    case 30: {
      return 'نصف ساعة';
    }
    case 60: {
      return 'ساعة';
    }
    case 90: {
      return 'ساعة و نصف';
    }
    case 120: {
      return 'ساعتين';
    }
    default: {
      return 'نصف ساعة';
    }
  }
}

export function checkSlotDuration(startDate: string, endDate: string): { isValid: boolean, duration: number } {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diff = end.getTime() - start.getTime();
  const diffMinutes = Math.ceil(diff / (1000 * 60));
  return {
    isValid: [30, 60, 90, 120].includes(diffMinutes),
    duration: diffMinutes
  };
}

// tslint:disable-next-line:typedef
export function convertTimeFrom24To122(time: string) {
  const arr = time.split(':');
  const h = +arr[0] % 12 || 12;
  const ampm = (+arr[0] < 12 || +arr[0] === 24) ? 'ص' : 'م';
  return `${h}:${arr[1]} ${ampm}`;
}

export function getSafeDate(date: string): Date {
  return new Date(date.replace(/-/g, '/'));
}

export function convertMsToHM(milliseconds: number): string {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}
