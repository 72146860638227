<div class="holder">
  <h3 class="main__title">بفضل الله ثم أنتم</h3>
  <section class="content">
    <ng-container *ngFor="let stat of OurStats;let even = even">
      <section [class.middle]="even" class="sections">
        <section class="numbers">
          <ng-container *ngFor="let digit of NumberToDigits(stat.count)">
            <span class="number">{{digit}}</span>
          </ng-container>
        </section>
        <p class="h5 subtitle">{{stat.title}}</p>
      </section>
    </ng-container>
  </section>
</div>

