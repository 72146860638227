import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {StudentFullData} from '@app/modules/my-students/models/My-Students';
import {MyStudent} from '@core/@data/CoursesData/Session';

export interface MyStudentFullData {
  students: MyStudent[];
  active_users_count: number;
  inactive_users_count: number;
}

@Injectable({
  providedIn: 'root'
})
export class MyStudentsService {
  Url = `${env.ApiUrl}/teacher/students`;

  constructor(
    private http: HttpClient,
  ) {
  }


  GetAllStudents(isActive: number = 1): Observable<ApiResponse<MyStudentFullData>> {
    return this.http.get<ApiResponse<MyStudentFullData>>(`${this.Url}?active_only=${isActive}`);
  }

  GetAllStudentsWithoutFilters(): Observable<ApiResponse<MyStudentFullData>> {
    return this.http.get<ApiResponse<MyStudentFullData>>(`${this.Url}`);
  }

  GetSingleStudent(id: number): Observable<ApiResponse<StudentFullData>> {
    return this.http.get<ApiResponse<StudentFullData>>(`${this.Url}/${id}`);
  }

}// End of Class
