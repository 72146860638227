import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getPlanData'
})
export class GetPlanDataPipe implements PipeTransform {

  transform(value: number, isStudents: boolean): string {
    if (isStudents) {
      return this.GetPlanStudents(value);
    } else {
      return this.GetContactedValue(value);
    }
  }

  GetPlanStudents(value: number = 0): string {
    if (value === 1) {
      return 'طالب واحد';
    }
    if (value === 2) {
      return 'طالبين';
    }
    if (value === 3) {
      return 'ثلاث طلاب';
    } else {
      return 'طالب واحد';
    }
  }

  GetContactedValue(value: number): string {
    return ' حصة / حصص' + value;
  }
}
