import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {
  AppointmentsFilterType,
  CreateSession,
  PackageDetailsBySession,
  Session,
  TeacherCoursesSessions,
  TeacherSession,
  UpcomingSession
} from '@core/@data/CoursesData/Session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  Url = `${env.ApiUrl}/teacher/appointments`;

  constructor(
    private http: HttpClient,
  ) {
  }

  CreateSession(session: CreateSession): Observable<ApiResponse<Session>> {
    return this.http.post<ApiResponse<Session>>(`${this.Url}`, session);
  }

  GetAllSessions(withAvailable: boolean = false): Observable<ApiResponse<TeacherCoursesSessions>> {
    return this.http.get<ApiResponse<TeacherCoursesSessions>>(`${this.Url}?with_available=${withAvailable}`);
  }

  GetAllSessionsFiltered(AppointmentsFilterType: AppointmentsFilterType, page: number = 1, per_page = 15, withAvailable: boolean = false): Observable<ApiResponse<TeacherSession[]>> {
    return this.http.get<ApiResponse<TeacherSession[]>>(`${env.ApiUrl}/teacher/filter-appointments?filter=${AppointmentsFilterType}&page=${page}&with_available=${withAvailable}&per_page=${per_page}`);
  }

  GetAllSessionsFiltered2(AppointmentsFilterType: AppointmentsFilterType, withAvailable: boolean = false): Observable<ApiResponse<TeacherSession[]>> {
    return this.http.get<ApiResponse<TeacherSession[]>>(`${env.ApiUrl}/teacher/filter-appointments?filter=${AppointmentsFilterType}&with_available=${withAvailable}`);
  }

  ShowSessionDetails(id: number): Observable<ApiResponse<TeacherSession[]>> {
    return this.http.get<ApiResponse<TeacherSession[]>>(`${this.Url}`);
  }

  CancelSession(id: number): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(`${this.Url}/${id}/cancel`, {});
  }

  DeleteSession(id: number | string): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(`${this.Url}/${id}`, {});
  }

  JoinSession(id: number): Observable<ApiResponse<{ url: string }>> {
    return this.http.post<ApiResponse<{ url: string }>>(`${this.Url}/${id}/join`, {});
  }

  MarkSessionAsAttended(id: number): Observable<ApiResponse<unknown>> {
    return this.http.post<ApiResponse<unknown>>(`${this.Url}/${id}/mark-as-attended`, {});
  }

  MarkSessionStudentAsAttended(id: number): Observable<ApiResponse<unknown>> {
    return this.http.post<ApiResponse<unknown>>(`${this.Url}/${id}/mark-student-as-attended`, {});
  }

  GetPackageDetails(id: number): Observable<ApiResponse<PackageDetailsBySession>> {
    return this.http.get<ApiResponse<PackageDetailsBySession>>(`${env.ApiUrl}/teacher/user-packages/${id}`);
  }

  RateSession(id: number, body: { rate: number; comment: string }): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${this.Url}/${id}/rate`, body);
  }

  UpdateSessionReport(id: number, body: { report: string }): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(`${this.Url}/${id}/add-session-report`, body);
  }

  GetUpcomingSession(): Observable<ApiResponse<UpcomingSession>> {
    return this.http.get<ApiResponse<UpcomingSession>>(`${this.Url}/upcoming-session`);
  }

}// End of Class
