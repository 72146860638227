import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyCalendarComponent} from '@app/modules/my-calendar/pages/my-calendar/my-calendar.component';
import {NotFoundComponent} from '@shared/components/common-components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MyCalendarComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyCalendarRoutingModule {
}
