<section class="no__found">
  <svg height="500px" version="1.1" viewBox="0 0 837 1045" width="380px" xmlns="http://www.w3.org/2000/svg"
       xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
    <g fill="none" fill-rule="evenodd" id="Page-1" sketch:type="MSPage" stroke="none" stroke-width="1">
      <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z" id="Polygon-1"
            sketch:type="MSShapeGroup" stroke="#CF8B40" stroke-width="6"></path>
      <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
            id="Polygon-2" sketch:type="MSShapeGroup" stroke="#072E1A" stroke-width="6"></path>
      <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
            id="Polygon-3" sketch:type="MSShapeGroup" stroke="#68c950" stroke-width="6"></path>
      <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
            id="Polygon-4" sketch:type="MSShapeGroup" stroke="#EFC729" stroke-width="6"></path>
      <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
            id="Polygon-5"
            sketch:type="MSShapeGroup" stroke="#FFFFFF" stroke-width="6"></path>
    </g>
  </svg>
  <div class="message-box">
    <h1>404</h1>
    <p>هذة الصفحة غير موجودة</p>
    <div class="buttons-con">
      <div class="action-link-wrap">
        <a class="link-button link-back-button" onclick="history.go(-1)"> الرجوع للخلف </a>
        <a class="link-button" routerLink="/"> الذهاب للصفحة الرئيسية </a>
      </div>
    </div>
  </div>

</section>
