<div class="header">
  <h1 mat-dialog-title>يرجى إدخال لينك الميتنج</h1>
</div>
<div class="body__content" mat-dialog-content style="min-width: 50vw;min-height: 175px;">
  <form (ngSubmit)="onSubmit()" [formGroup]="updateProfileForm" dir="rtl">
    <div class="row">
      <!--  Link     -->
      <div class="col-12 my-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>لينك الميتنج</mat-label>
          <input formControlName="link" matInput placeholder="لينك الميتننج *" type="text"/>
          <mat-hint
            *ngIf="updateProfileForm.controls.link.errors?.required && updateProfileForm.controls.link.touched"
            align="start">يرحى إدخال اللينك
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn text-center m-auto">
      <app-submit-button [form]="updateProfileForm" [loading]="formLoading" [title]="'ارسال'"></app-submit-button>
    </div>
    <div class="submit-btn text-center m-auto">
      <button (click)="cancel()" class="button raised__button-special" type="button">
        إغلاق
      </button>
    </div>
  </form>
</div>

