<div class="header">
  <h1 mat-dialog-title>{{ data!.title }}</h1>
</div>
<div *ngIf="data!.message !== '' && data!.message" class="body__content"
     mat-dialog-content>
  <p class="dialog-message" dir="rtl">{{ data!.message }}</p>
</div>
<p *ngIf="loadingStudentsStatus === 'loading' || loadingStudentsStatus === 'error'" dir="rtl">
  {{ loadingStudentsStatus === 'loading' ? 'جاري التحميل...' : 'حدث خطأ أثناء التحميل' }}
</p>
<form *ngIf="loadingStudentsStatus === 'loaded'" class="w-100" dir="rtl">
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>طلابي</mat-label>
    <input [formControl]="studentCtrl"
           [matAutocomplete]="auto"
           aria-label="State"
           matInput
           placeholder="قم بالبحث عن الطالب / ة">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option (onSelectionChange)="selectStudent($event)"
                  *ngFor="let student of filteredStudents | async"
                  [value]="student.name">
        <img [defaultImageOnError]="student?.gender === 1 ?
                './assets/images/default-logos/login.svg' :
                './assets/images/default-logos/Group 11524.svg'"
             [src]="student.img |
                imgDefault:student?.gender === 1 ?
                './assets/images/default-logos/login.svg' :
                './assets/images/default-logos/Group 11524.svg'" aria-hidden class="example-option-img" height="25">
        <span>{{ student.name }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>عدد الدعوات *</mat-label>
    <input [formControl]="invitationsNumber" matInput placeholder="عدد الدعوات *" type="number"/>
  </mat-form-field>
</form>
<section class="actions__container" mat-dialog-actions>
  <button (click)="confirm()" *ngIf="data!.confirmText && data!.confirmText !== ''" class="button raised__button_white">
    {{ data!.confirmText }}
  </button>
  <button (click)="cancel()" *ngIf="data?.cancelText && data?.cancelText !== ''" class="button raised__button-special">
    {{ data!.cancelText }}
  </button>
</section>
