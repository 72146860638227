import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Country} from '../../@data/General/GeneralData';
import {ApiResponse} from '../../@data/Api/Api';
import {ContactUS} from '@core/@data/General/ContactUS';
import {Language} from '@core/@data/General/Language';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) {
  }

  GetAllCountries(): Observable<ApiResponse<Country[]>> {
    return this.http.get<ApiResponse<Country[]>>(`${env.ApiUrl}/student/countries`);
  }

  GetAllCountriesWithPagination(page: number): Observable<ApiResponse<Country[]>> {
    return this.http.get<ApiResponse<Country[]>>(`${env.ApiUrl}/countries?page=${page}`);
  }

  ContactUS(body: ContactUS): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/student/contact-us`, body);
  }

  GetAllLanguages(): Observable<ApiResponse<Language[]>> {
    return this.http.get<ApiResponse<Language[]>>(`${env.ApiUrl}/teacher/languages`);
  }

  GetAllLanguagesPaginated(page: number): Observable<ApiResponse<Language[]>> {
    return this.http.get<ApiResponse<Language[]>>(`${env.ApiUrl}/teacher/languages/?page=${page}`);
  }

  UpdateZoomData(link: string, id: number): Observable<{ link: string; message: string; }> {
    return this.http.post<{ link: string; message: string; }>(`${env.ApiUrl}/teacher/appointments/${id}/update-link`, {
      room_type: 'zoom',
      attendee_link: link
    });
  }
}// End of Class
