import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {LocalstorageService} from '@core/@http/services/localstorage.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[showGuest]'
})
export class GuestDirective implements OnInit {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public localStorageService: LocalstorageService,
  ) {
  }

  ngOnInit(): void {
    this.showGuest();
  }

  showGuest(): void {
    this.localStorageService.state$.subscribe((res) => {
      if (!res) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else {
        if (res?.token === null) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else {
          this.viewContainer.clear();
          this.hasView = false;
        }
      }
    });
  }
}
