import {CommonModule} from '@angular/common';
import {MaterialModule} from '@shared/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SafeYoutubeUrlPipe} from '@shared/pipes/safe-youtube-url.pipe';
import {TruncateTextPipe} from '@shared/pipes/truncate-text.pipe';
import {ImgDefaultPipe} from '@shared/pipes/img-default.pipe';
import {NameSplitPipe} from '@shared/pipes/name-split.pipe';
import {SafeUrlPipe} from '@shared/pipes/safe-url.pipe';
import {FilterPipe} from '@shared/pipes/filter.pipe';
import {HtmlPipe} from '@shared/pipes/html.pipe';
import {GetLengthPipe} from '@shared/pipes/get-length.pipe';
import {ArabicNumberPipe} from '@shared/pipes/ArabicNumberPipe .pipe';
import {GuestDirective} from '@shared/directives/guest.directive';
import {AuthDirective} from '@shared/directives/auth.directive';
import {CardHoverDirective} from '@shared/directives/Attributes-Direstives/card-hover.directive';
import {NotFoundComponent} from '@shared/components/common-components/not-found/not-found.component';
import {ScrollToTopComponent} from '@shared/components/layouts/scroll-to-top/scroll-to-top.component';
import {SubmitButtonComponent} from '@shared/components/form/submit-button/submit-button.component';
import {PasswordComponent} from '@shared/components/form/password/password.component';
import {LoaderComponent} from '@shared/components/layouts/loader/loader.component';
import {DialogueComponent} from '@shared/components/common-components/confirm-dialouge/dialogue.component';
import {TimesDiffPipe} from '@shared/pipes/times-diff.pipe';
import {GetPlanDataPipe} from '@shared/pipes/get-plan-data.pipe';
import {GetPlanStringsPipe} from '@shared/pipes/get-plan-strings.pipe';
import {SliceTextPipe} from '@shared/pipes/slice-text.pipe';
import {ForceLoginComponent} from '@shared/components/other/force-login/force-login.component';
import {SmallBarComponent} from '@shared/components/layouts/small-bar/small-bar.component';
import {RouterModule} from '@angular/router';
import {
  ContentSkeletonComponent
} from '@shared/components/common-components/content-skeleton/content-skeleton.component';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {DefaultImageOnErrorDirective} from '@shared/directives/default-image-on-error.directive';
import {FormatTimePipe} from '@shared/pipes/format-time.pipe';
import {GetSessionStatesPipe} from '@shared/pipes/get-session-states.pipe';
import {NameFilterPipe} from '@shared/pipes/name-filter.pipe';
import {CustomDatePipe} from '@shared/pipes/custom-date.pipe';
import {GetInvitationsStatesPipe} from '@shared/pipes/get-invitations-states.pipe';


export const imports: any[] = [
  CommonModule,
  MaterialModule,
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  ContentLoaderModule,
  LazyLoadImageModule
];
export const pipes: any[] = [
  SafeYoutubeUrlPipe,
  TruncateTextPipe,
  ImgDefaultPipe,
  NameSplitPipe,
  SafeUrlPipe,
  FilterPipe,
  HtmlPipe,
  GetLengthPipe,
  ArabicNumberPipe,
  TimesDiffPipe,
  GetPlanDataPipe,
  GetPlanStringsPipe,
  SliceTextPipe,
  FormatTimePipe,
  GetSessionStatesPipe,
  NameFilterPipe,
  CustomDatePipe,
  GetInvitationsStatesPipe
];
export const directives: any[] = [
  GuestDirective,
  AuthDirective,
  CardHoverDirective,
  DefaultImageOnErrorDirective
];
export const components: any[] = [
  NotFoundComponent,
  ScrollToTopComponent,
  ForceLoginComponent,
  SmallBarComponent,
  SubmitButtonComponent,
  PasswordComponent,
  LoaderComponent,
  DialogueComponent,
  ContentSkeletonComponent
];
