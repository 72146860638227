<div class="container main__container">
  <h1 class="main__title fw-bold">جدول المواعيد</h1>
  <h4 class="timezone">هذه المواعيد بتوقيت ( {{teacherAuthService?.userDataBehaviorSubject?.value?.timezone}} )</h4>
  <div class="calendar">
    <full-calendar
      *ngIf='calendarVisible'
      [options]='calendarOptions'
    ></full-calendar>
  </div>
</div>
