import {Course, SimpleCourse, SimpleCourse2, TeacherSimpleCourse} from '@core/@data/CoursesData/Course';
import {TeacherRate} from '@app/modules/student-rates/models/Rates';
import {User} from '@core/@data/UsersData/UserData';
import {TeacherCard} from '@core/@data/Teachers/Teacher';

export type AppointmentsFilterType =
  'available_sessions'
  | 'cancelled_sessions'
  | 'future_sessions'
  | 'past_sessions'
  | 'today_sessions'
  | 'tomorrow_sessions';

export interface CreateSession {
  start: string;
  end: string;
  suggested_user_id?: number;
  number_of_invitations?: number;
}

export interface Session {
  id: number;
  day: string;
  duration: string;
  end: string;
  join_state: JoinSessionState;
  start: string;
  state: SessionState;
}

export enum SessionState {
  AVAILABLE = 1,
  BOOKED = 2,
  CONFIRMED = 3,
  CANCELED = 4,
  REFUNDED = 5,
  STARTED = 6,
  DONE = 7
}

export enum JoinSessionState {
  NOT_JOINED_BY_ALL = 1,
  JOINED_BY_TEACHER = 2,
  JOINED_BY_STUDENT = 3,
  JOINED_BY_ALL = 4,
}

export interface TeacherCoursesSessions {
  available_sessions: TeacherSession[];
  past_sessions: TeacherSession[];
  today_sessions: TeacherSession[];
  tomorrow_sessions: TeacherSession[];
  future_sessions: TeacherSession[];
  cancelled_sessions: TeacherSession[];
}

export type canceledSessions = cancelSession & TeacherSession;

export interface cancelSession {
  cancelled_at: string;
  cancelled_by: string;
  comment: string;
}


export interface TeacherSession {
  id: number;
  duration: string;
  day: string;
  day_iso_format: string;
  start: string;
  start_iso_format: string;
  end: string;
  end_iso_format: string;
  join_state: JoinSessionState;
  state: SessionState;
  course: TeacherSimpleCourse;
  user: SessionUser | null;
  force_start: number;
  child: null | {
    age: number;
    gender: number;
    id: number;
    name: string;
  };
  minutes_to_start: number;
  now_in_milliseconds: number;
  start_in_milliseconds: number;
  user_package_id: number;
  teacher_rate: TeacherRate | null;
  cancelled_at: string;
  cancelled_by: string;
  comment: string;
  original_start: string;
  original_end: string;
  original_start_iso_format: string;
  original_end_iso_format: string;
  original_day_iso_format: string;
  original_minutes_to_start: number;
  original_start_in_milliseconds: number;
  original_now_in_milliseconds: number;
  report: string;
  suggested_user_id: number;
  suggested_user: MyStudent;
}

export enum AppointmentInvitationStatus {
  Pending = 1,
  Accepted = 2,
  Rejected = 3
}

export interface SessionUser {
  id: number;
  name: string;
  img: string;
  email: string;
  phone: string;
  birth_date: string;
  last_seen: string;
  state: number;
  gender: number;
}

export interface Appointment {
  id: number;
  day: string;
  start: string;
  end: string;
  start_iso_format: string;
  end_iso_format: string;
  day_iso_format: string;
  minutes_to_start: number;
  start_in_milliseconds: number;
  now_in_milliseconds: number;
  duration: string;
  state: number;
  join_state: number;
  force_start: number;
  comment: null;
  cancelled_by: null;
  cancelled_at: null;
  course: SimpleCourse2;
}

export interface Package {
  id: number;
  total_sessions_count: number;
  booked_sessions_count: number;
  remaining_sessions_count: number;
  canceled_sessions_count: number;
  refunded_sessions_count: number;
  payment_type: string;
  session_max_duration: string;
  expire_date: string;
  package_name: string;
  type: number;
  course_id: number;
  payment_plan_id: number | null;
  created_at: string;
  course: Course;
}

export type PackageDetailsBySession = Package & {
  user: MyStudent;
};

export interface MyStudent {
  id: number;
  name: string;
  img: string;
  email: string;
  phone: string;
  birth_date: string;
  last_seen: string;
  state: number;
  gender: number;
  facebook_profile: null | string;
  is_new: number;
}


export interface UpcomingSession {
  id: number;
  day: string;
  original_start: string;
  original_end: string;
  original_start_iso_format: string;
  original_end_iso_format: string;
  original_day_iso_format: string;
  original_minutes_to_start: number;
  original_start_in_milliseconds: number;
  original_now_in_milliseconds: number;
  start: string;
  end: string;
  start_iso_format: string;
  end_iso_format: string;
  day_iso_format: string;
  minutes_to_start: number;
  start_in_milliseconds: number;
  now_in_milliseconds: number;
  duration: string;
  state: number;
  join_state: number;
  force_start: number;
  comment: null;
  cancelled_by: string;
  cancelled_at: null;
  user_package_id: number;
  suggested_user_id: number;
  report: string;
  meeting_room: MeetingRoom;
  course: Course;
  user: User;
}

export interface MeetingRoom {
  id: number;
  appointment_id: number;
  meeting_room_id: string;
  name: string;
  moderator_password: string;
  attendee_password: string;
  moderator_link: null;
  attendee_link: string;
  room_type: string;
}

export interface SessionResponse {
  id: number;
  day: string;
  duration: string;
  end: string;
  join_state: JoinSessionState;
  start: string;
  start_iso_format: string;
  end_iso_format: string;
  state: SessionState;
  course: SimpleCourse;
  teacher: TeacherCard;
  force_start: number;
  minutes_to_start: number;
  now_in_milliseconds: number;
  start_in_milliseconds: number;
  original_day_iso_format: string;
  original_end: string;
  original_end_iso_format: string;
  original_minutes_to_start: number;
  original_now_in_milliseconds: number;
  original_start: string;
  original_start_in_milliseconds: number;
  original_start_iso_format: string;
  report: string;
  student_rate: {
    comment: string;
    id: number;
    rate: string;
    rate_by: string
  } | null;
  suggested_user_id: number;
  cancelled_by?: string;
  cancelled_at?: null;
}
