import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToasterService} from '@shared/services/toastr.service';

@Component({
  selector: 'app-dialogue',
  templateUrl: './dialogue.component.html',
  styleUrls: ['./dialogue.component.scss']
})
export class DialogueComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      cancelText: string;
      confirmText: string;
      message: string;
      title: string;
      isSessionLink: boolean;
    },
    private mdDialogRef: MatDialogRef<DialogueComponent>,
    private toasterService: ToasterService
  ) {
    console.log(data);
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public confirm(): void {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close(false);
  }

  @HostListener('keydown.enter')
  public onEnter(): void {
    this.close(true);
  }

  CodeCopied(e: any): void {
    if (e) {
      this.toasterService.showSuccess('تم نسخ الرابط');
    }
  }
}
