import {Pipe, PipeTransform} from '@angular/core';
import {convertTimeToNumber, formatDuration} from "@shared/functions/time-functions";

@Pipe({
  name: 'formatTime',
  pure: true
})
export class FormatTimePipe implements PipeTransform {
  transform(value: string): string {
    return formatDuration(convertTimeToNumber(value));
  }
}
